import api from '@/api'
import {copyIntoRecursive,
        default_getter as get,
        default_setter as set} from '@/store'
import { eCondition } from '@/components/generic/wizard/ibuyer-offer-optimizer/calculations'

const getInitialState = function () {
  return {
    leadID: null,
    lead: {
      address: {
          address1: "",
          address2: "",
          city: "",
          latitude: null,
          longitude: null,
          owner_name: "",
          state: "",
          zipcode: "", //string
      },
      claimed_date: null, //date
      created: null, //date
      delivered_date: null, //date
      email: "",
      first_name: "",
      last_bma: null,
      last_name: "",
      lead_id: null,
      lead_score: null,
      opened_date: null, //date
      phone: null,
      valuations: [],
          // Valuations array objs are of form:
          // {
          //     "estimate": 340468,
          //     "name": "CORELOGIC"
          // },
    },

    propertyCondition: 2, 

    iBuyer: {
      property_address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        owner_name: ""
      },
      relationship: "",           //"owner|agent|other",
      timing: "",                 //"ASAP|this month|1-3 months from now|3+ months from now|not sure",
      property_type: "",          //"house|condo|townhouse|multi-family|land|other",
      beds: null,                 //Numeric
      baths_full: null,           //Numeric
      baths_half: null,           //Numeric
      year_built: null,           //Numeric
      finished_sqft: null,        //Numeric
      lot_size: null,             //Numeric
      above_ground_sqft: null,    //Numeric
      below_ground_sqft: null,    //Numeric
      year_purchased: null,       //Numeric
      num_floors: null,           //Numeric
      num_fireplaces: null,       //Numeric
      basement_type: "",          //"full_finished,|full_unfinished|crawlspace|concrete_slab|other",
      garage_type: "",            //"detached|attached|carport",
      num_garage: null,           //Numeric
      master_bedroom_location: "",
      name: "",
      email: "",
      phone: ""
    },

    verifyMyValue: {
      property_address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        owner_name: ""
      },
      is_estimate_correct: true,    //Boolean
      original_estimate: null,    //Numeric
      user_estimate: null,        //Numeric
      property_type: "other",       //"house|condo|townhouse|multi-family|land|other",
      beds: null,                      //Numeric
      baths_full: null,                //Numeric
      baths_half: null,                //Numeric
      year_built: null,             //Numeric
      year_purchased: null,         //Numeric
      name: "",
      email: "",
      phone: ""
    },

    offerOptimizer: {
      property_address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        owner_name: ""
      },
      relationship: "",           //"owner|agent|other",
      timing: "",                 //"ASAP|this month|1-3 months from now|3+ months from now|not sure",
      condition: "",
      location: "",
      name: "",
      email: "",
      phone: ""
    },

    //loaders:
    loadingLead: true,
    loadingPropertyCondition: true,
  }
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    leadID:                     get('leadID'),
    lead:                       get('lead'),
    propertyCondition:          get('propertyCondition'),
    iBuyer:                     get('iBuyer'),
    verifyMyValue:              get('verifyMyValue'),

    //loaders:
    loadingLead:                get('loadingLead'),
    loadingPropertyCondition:   get('loadingPropertyCondition'),
  },
  mutations: {
    _resetState(state) {
      copyIntoRecursive(state, getInitialState())
    },
    setLead (state, value) {
      copyIntoRecursive(state.lead, value);
    },
    setVerifyMyValue (state, value) {
      copyIntoRecursive(state.verifyMyValue, value);
    },
    setLeadID:                  set('leadID'),
    setPropertyCondition:       set('propertyCondition'),

    loading_lead:               set('loadingLead'),
    loading_propertyCondition:  set('loadingPropertyCondition')
  },
  actions: {
    createLead ({commit, rootState}, {
        agentID,
        payload: {address, firstName, lastName, email, leadTypes} = {}
      } = {})
    {
      return api
        .post_createLead (agentID, {
          address: address || rootState.listings.address,
          firstName,
          lastName,
          email,
          leadTypes,
        })
        .then( function (lead_id) {
          commit('setLeadID', lead_id)
          return lead_id;
        })
    },
    updateLead ({state}, newLeadJSON) {
      return api
        .put_updateLead (
          newLeadJSON.lead_id || state.leadID,
          newLeadJSON
        )
    },
    getLeadByID ({commit}, leadID){
      commit('loading_lead', true)
      return api
        .getLeadByID(leadID)
        .then( resp => {
          commit('setLead', resp);
        })
        .finally( () => {
          commit('loading_lead', false)
        })
    },
    getPropertyCondition ({state, commit}, leadID) {
      commit('loading_propertyCondition', true)
      return api
        .getPropertyCondition( leadID || state.leadID )
        .then( resp => {
          let condition = Object.keys(resp)[0]
          commit('setPropertyCondition', parseInt(condition))
        })
        .catch( e => {
          console.error(e);
        })
        .finally( () => {
          commit('loading_propertyCondition', false)
        })
    },
    updatePropertyCondition ({state, commit}, {leadID = state.leadID, conditionValue}) {
      return api
        .post_updatePropertyCondition (leadID, conditionValue)
        .then(function (l) {
          // The leadID that is returned should be the same
          // so we don't need to commit it.
          // Return whether lead ID is same as expected or not
          commit('setPropertyCondition', parseInt(conditionValue))
          return (l === state.leadID)
        })
        .catch(function (err) {
          console.error(err);
          return false;
        })
    },
    subscribeMonthlyReport ({state}, payload) {
      return api
        .post_subscribeMonthlyReport (
          payload.leadID || state.leadID,
          payload
        )
        .catch ( err => {
          console.error(err);
          return false;
        })
    },
    unsubscribeMonthlyReport ({state}, payload) {
      return api
        .post_unsubscribeMonthlyReport (
          payload.leadID || state.leadID
        )
        .catch ( err => {
          console.error(err);
          return false;
        })
    },
    getVerifyMyValueInfo ({state, rootState, rootGetters}) {
      // Get data from listings and user store to populate object
      let listingsData = rootGetters['listings/iBuyer']
      let resp = {
        "property_address": {
          "address1": listingsData.address1,
          "address2": listingsData.address2,
          "city": listingsData.city,
          "state": listingsData.state,
          "zipcode": listingsData.zipcode,
          "latitude": listingsData.lat,
          "longitude": listingsData.lng,
          "owner_name": ""
        },
        "is_estimate_correct": true,                                                  //Boolean
        "original_estimate": listingsData.estimate,                                   //Numeric
        "user_estimate": null,                                                        //Numeric
        "property_type": listingsData.property_type,                                  //"house|condo|townhouse|multi-family|land|other",
        "beds": listingsData.beds,                                                    //Numeric
        "baths_full": listingsData.baths_full || listingsData.baths,                  //Numeric
        "baths_half": listingsData.baths_half,                                        //Numeric
        "baths_1quarter": null,
        "baths_3quarters": null,
        "year_built": listingsData.year_built,                                        //Numeric
        "year_purchased": (new Date(listingsData.last_sold_date)).getUTCFullYear(),   //Numeric
        "name": rootGetters['user/full_name'],
        "email": rootState.user.details.email,
        "phone": ""
      };

      //handle half/full baths discrepancies
      if (resp.baths_full !== (resp.baths_full | 0)) //there is a fractional component
      {
        resp.baths_half = (resp.baths_full - (resp.baths_full | 0)) * 2   //turn the fractional component into half-baths
        resp.baths_full |= 0;  // bitwise OR assignment operator          // truncate the full baths to an integer  
      }
      
      copyIntoRecursive(state.verifyMyValue, resp)
    },
    postVerifyMyValueInfo({state}) {
      //TODO: make sure leadID is present and has an appropriate type for this (possibly other types too)
      // if there is no leadID, generate a new one first before calling

      const req_obj = {
        // The below are keys used by Buyside currently
        'bedrooms':         state.verifyMyValue.beds,
        'bathrooms_total':  (1 * state.verifyMyValue.baths_full) + (0.5 * state.verifyMyValue.baths_half),
        'bathrooms_half':   state.verifyMyValue.baths_half,
        'bathrooms_full':   state.verifyMyValue.baths_full,
        'property_type':    state.verifyMyValue.property_type,
        // 'estimated_valuation_high', 
        // 'estimated_valuation_low',
        // 'lat',
        // 'lng',

        // The below properties aren't being used by Buyside yet
        year_built:     state.verifyMyValue.year_built,
        year_purchased: state.verifyMyValue.year_purchased,
        name:           state.verifyMyValue.name,
        email:          state.verifyMyValue.email,
        phone:          state.verifyMyValue.phone,
      }

      if (state.verifyMyValue.user_estimate)
        req_obj['estimated_value'] = state.verifyMyValue.user_estimate

      return api.post_editPropertyDetails(
        state.leadID,
        state.verifyMyValue.property_address,
        req_obj
      )
    },
    resetIBuyerInfo ({commit}) { commit('_resetState') }, //maybe delete me after testing?
    getIBuyerInfo ({state, rootState, rootGetters}) {
      //TODO: call to API (stub) goes here
      // Get data from listings and user store to populate object
      let listingsIbuyer = rootGetters['listings/iBuyer']
      let resp = {
        "property_address": {
          "address1": listingsIbuyer.address1,
          "address2": listingsIbuyer.address2,
          "city": listingsIbuyer.city,
          "state": listingsIbuyer.state,
          "zipcode": listingsIbuyer.zipcode,
          "latitude": listingsIbuyer.lat,
          "longitude": listingsIbuyer.lng,
          "owner_name": ""
        },
        "relationship": "",      //"owner|agent|other",
        "timing": "",       //"ASAP|this month|1-3 months from now|3+ months from now|not sure",
        "property_type": listingsIbuyer.property_type,     //"house|condo|townhouse|multi-family|land|other",
        "beds": listingsIbuyer.beds,                    //Numeric
        "baths_full": listingsIbuyer.baths_full || listingsIbuyer.baths,              //Numeric
        "baths_half": listingsIbuyer.baths_half,              //Numeric
        "year_built": listingsIbuyer.year_built,           //Numeric
        "finished_sqft": listingsIbuyer.finished_area,        //Numeric
        "lot_size": listingsIbuyer.lot_size,             //Numeric
        "above_ground_sqft": 0,    //Numeric
        "below_ground_sqft": 0,    //Numeric
        "year_purchased": listingsIbuyer.last_sold_date,       //Numeric
        "num_floors": listingsIbuyer.stories,           //Numeric
        "num_fireplaces": listingsIbuyer.fireplaces,       //Numeric
        "basement_type": "",          //"full_finished,|full_unfinished|crawlspace|concrete_slab|other",
        "garage_type": "",            //"detached|attached|carport",
        "num_garage": null,           //Numeric
        "master_bedroom_location": "",
        "name": rootGetters['user/full_name'],
        "email": rootState.user.details.email,
        "phone": ""
      };

      copyIntoRecursive(state.iBuyer, resp);
    },
    postIBuyerInfo ({state}) {
      //TODO: make sure leadID is present and has type 'iBuyer' (possibly other types too)
      // if there is no leadID, generate a new one first before calling
      return api.postIBuyerInfo (state.leadID, state.iBuyer)
    },
    getIBuyerOfferOptimizerInfo ({state, rootState, rootGetters}) {
      //TODO: call to API (stub) goes here
      // Get data from listings and user store to populate object
      let resp = {
        "property_address": {
          "address1": rootState.listings.address.address1,        
          "address2": rootState.listings.address.address2,    
          "city": rootState.listings.address.city,
          "state": rootState.listings.address.state,
          "zipcode": rootState.listings.address.zipcode,
          "latitude": rootState.listings.lat,
          "longitude": rootState.listings.lng,
          "owner_name": ""
        },
        "relationship": "",   //"owner|agent|other",
        "timing": "",         //"ASAP|this month|1-3 months from now|3+ months from now|not sure",
        "condition": (state.propertyCondition >= 3 ? 
          eCondition.Excellent : eCondition.MinorRepairs
        ),
        //"location": "",     // <-- this one is handled in IBuyerOfferOptimizer.vue, so we don't include it here (which would overwrite it)
        "name": rootGetters['user/full_name'],
        "email": rootState.user.details.email,
        "phone": ""
      };

      copyIntoRecursive(state.offerOptimizer, resp);
    },
    getReportOptions(context, reportID) {
      return api.getReportOptions(reportID);
    },
  }
}
