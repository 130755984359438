import api from '@/api';

const getInitialState = function() {
  return {
    report_details: [],
    loadingReportDetails: false,
  };
}

export default {
    namespaced: true,
    state: getInitialState(),
    getters: {
        report_details: state => state.report_details,
        loadingReportDetails: state => state.loadingReportDetails,
    },
    mutations: {
        setReportDetails(state, report_details) {
            state.report_details = report_details;
            state.report_details.cdn_link = 'https://devcdn.v1.buyermls.com/';
        },
        loadingReportDetails(state, value) {
            state.loadingReportDetails = value;
        }
    },
    
    actions: {
        getReportDetails({ commit }, params){
            commit('loadingReportDetails', true);
            return api.getReportDetails(params)
            .catch( e => {
                commit('setFailure', true, {root: true})
                throw e
            })
            .finally(() => commit('loadingReportDetails', false))
        }
    }
}