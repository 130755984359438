<template>
  <b-img
    v-bind="$_filteredAttrs"
    v-on="$_filteredListeners"
    @error="$_fallbackToNextImage" 
    :src="$_currentSource"
  />
</template>

<script>
export default {
  name: 'BImgFallback',
  inheritAttrs: false,
  data() {
    return {
      arrIdx: 0
    }
  },
  computed: {
    $_sources() {
      let ret = []

      if(typeof this.sources == "string")
        ret.push(this.sources)
      else if (Array.isArray(this.sources))
        ret = this.sources.filter( x => x ) //remove falsy values
      
      if('src' in this.$attrs)
        ret.push(this.$attrs['src'])

      return ret
    },
    $_currentSource() {
      if (this.arrIdx >= this.$_sources.length)
        return null

      return this.$_sources[this.arrIdx]
    },
    $_filteredAttrs() {
      const localAttrs = {...this.$attrs};
      
      delete localAttrs.src // if it exists

      return localAttrs
    },
    $_filteredListeners() {
      const localListeners = {...this.$listeners}

      delete localListeners.error // if it exists

      return localListeners
    }
  },
  methods: {
    $_fallbackToNextImage() {
      this.arrIdx++;

      if(this.arrIdx >= this.$_sources.length) {
        if('error' in this.$listeners) {
          // Call the error callback(s) now that all image sources failed
          const errorCallback = this.$listeners.error
          if(Array.isArray(errorCallback))
            errorCallback.forEach(fn => fn())
          else errorCallback()
        }
      }
    },
    $_reset() {
      this.arrIdx = 0
    }
  },
  props: {
    sources: {
      type: [Array, String],
      required: true,
      default: () => []
    }
  },
  watch: {
    sources() {
      this.$_reset();  
    }
  }
}
</script>