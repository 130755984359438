import Vue from 'vue'
import store from './store'

Vue.filter('formatDate', function (value, timezone = undefined) {
  if (value) {
    var date = new Date(value)
    return date.toLocaleDateString(['en-US'], {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: timezone
    })
  }
  return value
})

export const formatPrice = function (value) {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(Math.round(value * 100)/100) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(Math.round(value * 100)/100) ? 0 : 2
  })
  return formatter.format(value === 0 ? 0 : value) // removes "-$0"
}
Vue.filter('formatPrice', formatPrice)
Vue.filter('formatPriceOrDashes', function(value, zeroIsAllowed = false) {
  const valueAsNumber = Number(value)
  if(!Number.isNaN(valueAsNumber) && (zeroIsAllowed || valueAsNumber > 0)) {
    return formatPrice(valueAsNumber)
  }
  else return '--'
})

const formatNumber = function (value) {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US')
  return formatter.format(value)
}
Vue.filter('formatNumber', formatNumber)
Vue.filter('formatNumberOrDashes', function(value, zeroIsAllowed = false) {
  const valueAsNumber = Number(value)
  if(!Number.isNaN(valueAsNumber) && (zeroIsAllowed || valueAsNumber > 0)) {
    return formatNumber(valueAsNumber)
  }
  else return '--'
})

Vue.filter('formatPhone', function (value) {
  if(typeof value == 'number')
    value = value + "";

  if(typeof value == 'string')
  {
    if(value.length == 10 && value.match(/\D+/g) == null) //10-digit, only numbers
    {
      return value.slice(0,3) + '-' + value.slice(3,6) + '-' + value.slice(6);
    }
  }

  return value;
})

Vue.filter('formatMonth', function (monthNum) {
  switch(monthNum) {
    case 1:  return 'January';
    case 2:  return 'February';
    case 3:  return 'March';
    case 4:  return 'April';
    case 5:  return 'May';
    case 6:  return 'June';
    case 7:  return 'July';
    case 8:  return 'August';
    case 9:  return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '';
  }
})

Vue.filter('formatMonth3Letter', function (monthNum) {
  switch(monthNum) {
    case 1:  return 'Jan';
    case 2:  return 'Feb';
    case 3:  return 'Mar';
    case 4:  return 'Apr';
    case 5:  return 'May';
    case 6:  return 'Jun';
    case 7:  return 'Jul';
    case 8:  return 'Aug';
    case 9:  return 'Sep';
    case 10: return 'Oct';
    case 11: return 'Nov';
    case 12: return 'Dec';
    default: return '';
  }
})

Vue.filter('addSpaceToBBoxBounds', function (value) {
  if (value && value.length) {
    var boxArea = []
    for (var b = 0, len = value.length; b < len; b++) {
      boxArea.push(b > 1 ? value[b] + 0.001 : value[b] - 0.001)
    }
    return boxArea
  }
  return value
})

/*
    Color     as hex value with #, e.g. #A4F38F
    Decimal   as number between -1 and 1.
      -1        returns pure black
      -.5       returns 50% shade of input color (halfway between color and #000)
      0         returns unaltered color value
      .5        retunrs 50% tint of input color (halfway between color and #FFF)
      1         returns pure white
*/
//this is used in multiple filters so it is defined here first
let shadeFn = function(color, decimal) {
  function hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }

  function colorHex(r, g, b) {
    return "#" + hex2(r) + hex2(g) + hex2(b);
  }

  function shade(col, light) {

    // TODO: Assert that col is good and that -1 < light < 1

    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);

    if (light < 0) {
        r = (1 + light) * r;
        g = (1 + light) * g;
        b = (1 + light) * b;
    } else {
        r = (1 - light) * r + light * 255;
        g = (1 - light) * g + light * 255;
        b = (1 - light) * b + light * 255;
    }

    return colorHex(r, g, b);
  }
  return shade(color, decimal)
}
Vue.filter('shade', shadeFn )

/*
  getDispersedChartColorsFn

  Returns a function that generates an array of proportionally
  shaded/tinted hex colors based on the input baseColor and
  a number of colors to generate, numColors
*/
Vue.filter('getDispersedChartColorsFn', (baseColor, variation = 1.0) => (numColors) => {

    let arr = [];
    for(let j = 0; j < numColors; j++)
    {
      // The math below will generate a 0-centered range of numbers
      // bounded by [-0.5, 0.5] to ensure we don't make too-dark
      // or too-light colors. The baseColor ("0") will always be
      // included in this range. This is passed to the shade function
      // above to generate a range of hex color values.
      arr.push(
        shadeFn(baseColor,
          (j + 1 - Math.ceil((numColors+1)/2.0)) * (variation / numColors)
        )
      );
    }
    return arr;
})

// for Market Activity, etc., house listing card
Vue.filter('getDot', function (status, mode = "generic") {
  const colorLibraryBH = {
    'pending' : 'green-bh',
    'sold'    : 'blue-bh',
    'active'  : 'red-bh',
    'inactive': 'inactive'
  }
  const colorLibrary = {
    'pending' : 'yellow',
    'sold'    : 'red',
    'active'  : 'blue',
    'inactive': 'inactive'
  }

  if(mode == 'berkshire')
    return colorLibraryBH[status];
  else
    return colorLibrary[status];
})

Vue.filter('replaceWLCKeywords', function (string) {
  let newString = string;

  newString = newString.replace(/#buyer_matches_interval#/g, store.state.config.config.buyer_matches_interval || "90")
  newString = newString.replace(/#property_address#/g, store.getters["listings/address"] || "the address")
  newString = newString.replace(/#property_zipcode#/g, store.getters["listings/zipcode"] || "this zipcode")

  return newString;
})

Vue.filter('normalizeListingStatus', function(dbStatus) {
  switch (dbStatus?.toLowerCase?.()) {
    case "sold":
    case "closed":
    case "sale":
      return "sold"

    case "quiet":
    case "active":
      return "active"

    case "contingent":
    case "pending":
      return "pending"

    default:
      return "active"
  }
})

export const normalizePropertyType = function(property_type) {
  // Buyside gets all sorts of different values for property types from various vendors.
  // This is an attempt to normalize things, because the backend doesn't.

  /* Domain:
    {value: 1,  text: 'Single Family'},
    {value: 2,  text: 'Condo / Townhouse'},
    {value: 3,  text: 'Multi Family'},
    {value: 4,  text: 'Commercial'},
    {value: 5,  text: 'Lot / Land'},
    {value: 6,  text: 'Coop'},
    {value: 7,  text: 'Business Opportunity'},
    {value: 8,  text: 'Farm / Ranch'},
    {value: 9,  text: 'Patio Home'},
    {value: 10, text: 'Apartment'},
    {value: 11, text: 'Mobile Home'},
    {value: 12, text: 'Boatslip'},
    {value: 13, text: 'Timeshare'},
    {value: 14, text: 'Adult Community'},
    {value: 15, text: 'Lake Front'},
  */
  const propTypeTrimmed = String(property_type).trim()

  switch(propTypeTrimmed.toLowerCase()) {
    case "single family":
    case "single-family":
    case "singlefamily":
    case "single family detached":
      return "Single Family"

    case "condo":
    case "condominium":
    case "townhouse":
    case "townhouse/rowhouse":
    case "condo/townhouse":
    case "townhouse/row/cluster":
      return "Condo / Townhouse"

    case "multi family":
    case "multi-family":
    case "multi family dwelling":
    case "multi family 10 units less":
    case "multifamily2to4":
    case "multifamily5plus":
    case "twin/semi-attached":
    case "duplex/triplex/quadplex":
    case "tenancy in common":
      return "Multi Family"

    case "land":
    case "vacant land (nec)":
    case "vacantresidentialland":
      return "Lot / Land"

    case "co-op":
      return "Coop"

    case "cluster/patio home":
      return "Patio Home"

    case "farm/ranches":
      return "Farm / Ranch"

    case "mfg/mobile home":
      return "Mobile Home"

    default:
      return propTypeTrimmed
  }
}
Vue.filter('normalizePropertyType', normalizePropertyType)