import Vue from "vue"
import Router, { Route } from "vue-router"
import store from '../store';

Vue.use(Router)

/*
  include SAGE:
    if APP_CLIENT = berkshire ||
    if NODE_ENV = development

  include Generic:
    if ISNULL(APP_CLIENT,"") != berkshire ||
    if NODE_ENV = development

  include Dev:
    if NODE_ENV = development
*/

const allRoutes = []

// DEV
if (process.env.VUE_APP_NODE_ENV === "development") {
  const Home              = () => import("@/components/pages/Home.vue"              /* webpackChunkName: "Home"             */) // prettier-ignore

  allRoutes.push(
    ...[
      {
        path: "/home",
        component: Home,
        props: (route: Route) => ({ query: route.query.q })
      }
    ]
  )
}

// SAGE
if (process.env.VUE_APP_CLIENT === "berkshire" || process.env.VUE_APP_NODE_ENV === "development") {
  // Unused in production for now:
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    const IFrameHolderBH  = () => import("@/components/pages/berkshire/IFrameHolderBH.vue"  /* webpackChunkName: "IFrameHolderBH" */) // prettier-ignore
    const Page2BH         = () => import("@/components/pages/berkshire/Page2BH.vue"         /* webpackChunkName: "Page2BH"        */) // prettier-ignore
    const HomeEquityBH    = () => import("@/components/pages/berkshire/HomeEquityBH.vue"    /* webpackChunkName: "HomeEquityBH"   */) // prettier-ignore

    allRoutes.push(
      ...[
        {
          path: "/page-2-bh/:address/:city/:state/:zipcode/:agentId/iframe",
          component: Page2BH,
          props: () => ({ iframe: true }),
          meta: { isSAGE: true }
        },
        {
          path: "/page-2-bh/:address/:city/:state/:zipcode/:agentId",
          component: Page2BH,
          meta: { isSAGE: true }
        },
        {
          path: "/iframe-holder-bh/:address/:city/:state/:zipcode/:agentId",
          component: IFrameHolderBH,
          meta: { isSAGE: true }
        },
        {
          path: "/home-equity-report/:address/:city/:state/:zipcode/:agentId",
          component: HomeEquityBH,
          meta: { isSAGE: true }
        }
      ]
    )
  }

  const SellerActivityReportBH  = () => import("@/components/pages/berkshire/SellerActivityReportBH.vue"  /* webpackChunkName: "SellerActivityReportBH" */) // prettier-ignore
  const SellerActivityEmailBH   = () => import("@/components/pages/berkshire/SellerActivityEmailBH.vue"   /* webpackChunkName: "SellerActivityEmailBH"  */) // prettier-ignore
  const MarketActivityReportBH  = () => import("@/components/pages/berkshire/MarketActivityReportBH.vue"  /* webpackChunkName: "MarketActivityReportBH" */) // prettier-ignore

  allRoutes.push(
    ...[
      {
        path: "/seller-activity-report/:listingID/:agentId",
        component: SellerActivityReportBH,
        meta: { isSAGE: true }
      },
      {
        path: "/seller-activity-email/:listingID/:agentId",
        component: SellerActivityEmailBH,
        meta: { isSAGE: true }
      },
      {
        path: "/market-activity-report/:address/:city/:state/:zipcode/:agentId",
        component: MarketActivityReportBH,
        meta: { isSAGE: true }
      },
      {
        path: "/market-activity-report/:zipcode/:city/:state/:agentId/",
        component: MarketActivityReportBH,
        props: () => ({ zipcodeOnlyMAR: true }),
        meta: { isSAGE: true, zipcodeOnlyMAR: true }
      },
      {
        path: "/market-activity-report/:zipcode/:city/:agentId/",  /* We have to remove this route after a deployment days */
        component: MarketActivityReportBH,
        props: () => ({ zipcodeOnlyMAR: true }),
        meta: { isSAGE: true, zipcodeOnlyMAR: true }
      },
      {
        path: "/market-activity-report-county/:county/:state/:agentId/",
        component: MarketActivityReportBH,
        props: () => ({ countyOnlyMAR: true }),
        meta: { isSAGE: true, countyOnlyMAR: true }
      }
    ]
  )
}

// Special Generic SAR-Only Build
if (process.env.VUE_APP_CLIENT === "genericSAR") {

  const SellerActivityReport  = () => import("@/components/pages/SellerActivityReport.vue"  /* webpackChunkName: "SellerActivityReport" */) // prettier-ignore
  const Buyside404            = () => import("@/components/loaders/Buyside404.vue"          /* webpackChunkName: "Buyside404" */)           // prettier-ignore

  allRoutes.push(
    ...[
      {
        path: "/:listingID/:agentId",
        component: SellerActivityReport
      },
      {
        path: "/404",
        name: "404",
        component: Buyside404,
        props: true
      },
      {
        // Send all uncaught routes to 404 page
        path: "*",
        redirect: "/404"
      }
    ]
  );
}

// Generic
else if (process.env.VUE_APP_CLIENT === "development" || process.env.VUE_APP_CLIENT === "generic" || process.env.VUE_APP_NODE_ENV === "development") {
  // Unused in production for now:
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    const SellerActivityReport  = () => import("@/components/pages/SellerActivityReport.vue"  /* webpackChunkName: "SellerActivityReport" */) // prettier-ignore
    const MarketActivityReport  = () => import("@/components/pages/MarketActivityReport.vue"  /* webpackChunkName: "MarketActivityReport" */) // prettier-ignore
    const BMA                   = () => import("@/components/pages/BMA.vue"                   /* webpackChunkName: "BMA"                  */) // prettier-ignore
    const BMA_PDF               = () => import("@/components/pages/BMA_PDF.vue"               /* webpackChunkName: "BMA_PDF"              */) // prettier-ignore

    allRoutes.push(
      ...[
        {
          path: "/buyside-seller-activity-report/:listingID/:agentId",
          component: SellerActivityReport
        },
        {
          path: "/buyside-market-activity-report/:address/:city/:state/:zipcode/:agentId",
          component: MarketActivityReport
        },
        {
          path: "/bma/pdf/:address/:city/:state/:zipcode/:agentId",
          component: BMA_PDF
        },
        {
          path: "/bma/:address/:city/:state/:zipcode/:agentId",
          component: BMA
        },
        {
          path: "/bma/:address/:city/:state/:zipcode/",
          component: BMA
        }
      ]
    )
  }

  const HomeValuation             = () => import('@/components/pages/HomeValuation.vue'                    /* webpackChunkName: "HomeValuation"             */) // prettier-ignore
  const Page2                     = () => import('@/components/pages/Page2.vue'                            /* webpackChunkName: "Page2"                     */) // prettier-ignore
  const Page2Lender               = () => import("@/components/pages/Page2Lender.vue"                      /* webpackChunkName: "Page2Lender"               */) // prettier-ignore
  const IBuyer                    = () => import('@/components/pages/IBuyer.vue'                           /* webpackChunkName: "IBuyer"                    */) // prettier-ignore
  const IBuyerOfferOptimizer      = () => import("@/components/pages/IBuyerOfferOptimizer.vue"             /* webpackChunkName: "IBuyerOfferOptimizer"      */) // prettier-ignore
  const IBuyerOfferOptimizer_PDF  = () => import("@/components/pages/IBuyerOfferOptimizer_PDF.vue"         /* webpackChunkName: "IBuyerOfferOptimizer_PDF"  */) // prettier-ignore
  const BuyerDetails              = () => import('@/components/pages/DetailPages/BuyerDetails.vue'         /* webpackChunkName: "BuyerDetails"              */) // prettier-ignore
  const ListingDetails            = () => import('@/components/pages/DetailPages/ListingDetails.vue'       /* webpackChunkName: "ListingDetails"            */) // prettier-ignore

  allRoutes.push(
    ...[
      {
        path: "/",
        component: HomeValuation,
        props: (route: Route) => ({ query: route.query.q })
      },
      {
        path: "/buyer-details/:buyerID/:agentId",
        component: BuyerDetails
      },
      {
        path: "/listing-details/:listingID/:agentId",
        component: ListingDetails
      },
      {
        path: "/page-2/:address/:city/:state/:zipcode/:agentId",
        component: Page2
      },
      {
        path: "/page-2/:address/:city/:state/:zipcode/",
        component: Page2
      },
      {
        path: "/page-2-lender/:address/:city/:state/:zipcode/:agentId",
        component: Page2Lender
      },
      {
        path: "/page-2-lender/:address/:city/:state/:zipcode/",
        component: Page2Lender
      },
      {
        path: "/ibuyer",
        component: IBuyer,
        props: () => ({ sessionID: 12345 }) //this is just dummy for now
      },
      {
        path: "/ibuyer-offer-optimizer/pdf/:address/:city/:state/:zipcode/:agentId",
        component: IBuyerOfferOptimizer_PDF
      },
      {
        path: "/ibuyer-offer-optimizer/pdf/:address/:city/:state/:zipcode",
        component: IBuyerOfferOptimizer_PDF
      },
      {
        path: "/ibuyer-offer-optimizer/:address/:city/:state/:zipcode/:agentId",
        component: IBuyerOfferOptimizer
      },
      {
        path: "/ibuyer-offer-optimizer/:address/:city/:state/:zipcode",
        component: IBuyerOfferOptimizer
      },
      {
        path: "/home-valuation/:agentId",
        component: HomeValuation
      },
      {
        path: "/home-valuation/",
        component: HomeValuation
      },
      {
        path: "/:agentId",
        component: HomeValuation
      },
      {
        // For Generic, route all 404s to Home Valuation
        path: "*",
        redirect: "/"
      }
    ]
  )
}

// TODO: Make a global 404 route. What do we do for Berkshire?
//
// allRoutes.push(...[
//   // Redirect any unmatched routes to the 404 page. This may
//   // require some server configuration to work in production:
//   // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
//   {
//     path: '*',
//     redirect: '404',
//   },
// ])

export default new Router({
  routes: allRoutes,
  mode: "history",
  base: process.env.VUE_APP_CLIENT === "genericSAR" ? "/seller-activity-report/" : "/"
})
