<template>
  <b-modal id="modalOneall" hide-footer title="VERIFY OWNERSHIP" refs="modalOneall" class="modalOneall" centered @shown="onShow">
    <b-container id="innerContainer" class="mx-auto">
      <VSectionLoader v-if="loading" />
      <div v-else>
        <b-row>
          <b-col cols="24">
            <p>To claim and/or edit this property, please login by any of the following means.</p>
            <hr />
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col cols="24">
            <div id="oa_social_login_container"></div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-modal>
</template>

<script>

import api from '@/api'

export default {
  name: 'OneallModal',
  data() {
    return {
      loading: true
    }
  },
  methods: {
    onShow() {
      this.loading = true

      const callback_base = api.host + '/consumer/callback';
      const callback_uri = callback_base + "?site=http://" + document.location.host + document.location.pathname;

      const remove_loader = () => {this.loading = false};
      window._oneall.push(['social_login', 'add_event', 'on_widget_loaded', remove_loader]);
      window._oneall.push(['social_login', 'set_providers', ['google', 'facebook', 'linkedin']]);
      window._oneall.push(['social_login', 'set_callback_uri', callback_uri]);
      window._oneall.push(['social_login', 'do_render_ui', 'oa_social_login_container']);
    },
  }
}

</script>

<style lang="scss">
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';

.modalOneall {

  //semi-transparent white bg
  background-color: rgba(255,255, 255,0.8);

  header.modal-header {
    border-bottom: 0 !important;
    margin-bottom: 0 !important; //override headers from main bg page
  }

  #innerContainer
  {
    max-width: 584px;
    display: block;
    margin-top: -30px;
  }
  .modal-dialog{
    max-width: 800px;
  }
  .modal-body {
    margin-bottom:2em;
  }

  .form-group {
    min-width: 200px;
  }

  .row {
    padding-top: 0.25em;
  }

  .col {
    padding-left: 12px;
    padding-right: 12px;
  }

  input, select, textarea, .input-group-text, .custom-control-input {
    border-color: $dropshadow;
  }
  input, select {
    height: 44px;
  }

  button {
    margin-top: 14px;
    padding: 10px 54px;
  }

  //turn off number increment arrows on number inputs
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder{
    color: $placeholder_text;
  }

  hr{
    border: 0.5px solid #333;
    margin-left: 0;
    margin-right: 0;
  }

  label, input, select, textarea {
    @include fontclass-p();
    line-height: 18px;
  }

  label {
    color: $primary;
    font-weight: bold;
  }

}

</style>
