import api from '@/api'
import {
  copyIntoRecursive,
  default_getter as get,
  default_setter as set
} from '@/store'

const getInitialState = function () {
  return {
    details: {
      display_name: null,
      email: null,
      first_name: null,
      last_name: null,
      photo: null
    },
    properties: [],
    subscriptions: [],
    connectionToken: null, //'c7a27a66-22f5-48d9-ace8-2f71db38d172',
    isLoggedIn: false,

    loadingUser: false,
    loadingUserProperties: false
  }
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    details:          get('details'),
    connectionToken:  get('connectionToken'),
    isLoggedIn:       get('isLoggedIn'),
    properties:       get('properties'),
    subscriptions:    get('subscriptions'),
    
    full_name (state) {
      let the_name = ''
      the_name += (state.details.first_name) ? state.details.first_name + ' ' : ''
      the_name += (state.details.last_name) ? state.details.last_name : ''
      return the_name
    }
  },
  mutations: {
    _resetState(state) {
      copyIntoRecursive(state, getInitialState())
    },
    setUser (state, value) {
      if(value) {
        state.details.display_name = value.display_name
        state.details.email = value.email
        state.details.first_name = value.given_name
        state.details.last_name = value.family_name
        state.details.photo = value.photo
        state.properties = value.my_properties
        state.subscriptions = value.monthly_report_subscriptions
        state.isLoggedIn = true
      } else {
        state.details = {
          display_name: null,
          email: null,
          first_name: null,
          last_name: null,
          photo: null
        }
        state.properties = []
        state.subscriptions = []
        state.isLoggedIn = false
      }
    },
    setProperties (state, value) {
      state.properties = value ? value.my_properties : []
    },
    addSubscription (state, value) {
      let newSubscription = {
        lead_id: value
      }
      state.subscriptions.push(newSubscription)
    },
    removeSubscription (state, value) {
      let newArray = state.subscriptions.filter(function( obj ) {
          return obj.lead_id !== value
      })
      state.subscriptions = newArray
    },
    setConnectionToken:     set('connectionToken'),
    loadingUser:            set('loadingUser'),
    loadingUserProperties:  set('loadingUserProperties')
  },
  actions: {
    getUser ({ commit, state }) {
      commit('loadingUser', true)
      return api
        .getUserInformation(state.connectionToken)
        .then(function(response) {
          if(response.status) {
            commit('setUser', response)
          } else {
            commit('setUser', null)
            commit('setConnectionToken', null)
          }
          commit('loadingUser', false)
        })
    },
    getUserProperties ({ commit, state }) {
      commit ('loadingUserProperties', true)
      return api
        .getUserProperties(state.connectionToken)
        .then(function(response) {
          if(response.status) {
            commit('setProperties', response)
          }
          commit('loadingUserProperties', false)
        })
    },
    setConnectionToken ({ commit }, connectionToken) {
      commit('setConnectionToken', connectionToken)
    },
    logout ({ commit }) {
      window.$cookies.remove('buyside.connectionToken')
      commit('setUser', false)
      commit('setConnectionToken', null)
      window.location =  window.location.pathname
    },
    claimProperty ({ rootState, state }) {
      let leadID = rootState.leads.leadID
      let emailAddress = state.details.email
      let living_area = rootState.listings.finished_area
      let valuation_estimate = rootState.listings.estimate
      let connectionToken = state.connectionToken
      if(leadID && emailAddress && connectionToken) {
        return api.
        claimProperty(leadID, emailAddress, living_area, valuation_estimate, connectionToken)
      } else {
        console.log('missing a value', leadID , emailAddress , connectionToken)
        return false
      }
    },
    unclaimProperty ({ state }, payload ) {
      return api
        .unclaimProperty (
          payload || state.leadID, state.connectionToken
        )
        .catch ( err => {
          console.error(err);
          return false;
        })
    }
  }
}
