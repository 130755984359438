<template>
  <div id="app" :class="{'mobile': this.$options.isMobile}">
    <VBuysideFail v-if="failure" />
    <template v-else>
      <VBuysideSpinner v-if="spinner"/>
      <router-view v-show="!spinner"></router-view>
    </template>

    <OneallModal modalId="modalOneall" />
    <ClaimModal modalId="modalClaim" />
  </div>
</template>

<script>
import OneallModal from '@/components/generic/OneallModal'
import ClaimModal from '@/components/generic/ClaimModal'

import objectFitImages from 'object-fit-images';

import {mapGetters, mapState} from 'vuex'

export default {
  name: 'app',
  components: {
    OneallModal,
    ClaimModal
  },
  mounted() {
    objectFitImages();
  },
  computed: {
    ...mapGetters('config', ['themeCSS']),
    ...mapState('config', ['config', 'loadingConfig']),
    ...mapState(['spinner','failure']),
  },
  metaInfo() {
    if(this.loadingConfig)
      return {
        title: 'Buyside (Loading...)'
      };

    return {
      title: this.config.meta_title || null, //null means it falls back to default logic (aka static title in index.html)
      meta: [
        {name: 'copyright',           content: this.config.meta_copyright},
        {name: 'description',         content: this.config.meta_description},
        {name: 'keywords',            content: this.config.meta_keywords},
        
        // Facebook
        //{property: "fb:app_id",       content: this.config.facebook_app_id},

        // Open Graph
        {name: 'og:description',      content: this.config.ogdescription},
        {name: 'og:image',            content: this.config.ogimage},
        {name: 'og:site_name',        content: this.config.ogsite_name},
        {name: 'og:title',            content: this.config.ogtitle},
        {name: 'og:type',             content: this.config.ogtype},
        {name: 'og:url',              content: this.config.ogurl}, //TODO: what to do with this on subpages...
        // og_image_buyer <-- what do we do with this?

        // Twitter
        {name: 'twitter:card',        content: 'summary'},
        {name: 'twitter:site',        content: this.config.ogurl}, //TODO: what to do with this on subpages...
        {name: 'twitter:title',       content: this.config.ogtitle},
        {name: 'twitter:description', content: this.config.ogdescription},
        {name: 'twitter:image:src',   content: this.config.ogimage},

        // Google / Schema.org markup:
        {itemprop: 'name',            content: this.config.ogtitle},
        {itemprop: 'description',     content: this.config.ogdescription},
        {itemprop: 'image',           content: this.config.ogimage}
      ],
      link: [
        //{rel: 'canonical', href: this.config.ogurl}               //TODO: what to do with this on subpages...
      ],
      style: [
        {cssText: this.themeCSS, type: 'text/css'}
      ]
    }
  },
  created() {
    var connectionToken = this.$cookies.get('buyside.connectionToken')
    if(connectionToken) {
      this.$store.dispatch('user/setConnectionToken', connectionToken)
    }
  }
}
</script>

<style lang="scss">

@import "./styles/custom.scss";
@import "~bootstrap-slider/src/sass/bootstrap-slider.scss";

body,
#app header#header_generic,
#app header#header_berkshire
{
  overflow-x: hidden
}

</style>
