<template>
  <div class='buyside_fail_container mx-auto text-center'>
    <div class='buyside_fail'>
      <BuysideLogo />
      <p class="text-center error_text">An error occured. Please try again.</p>
    </div>
  </div>
</template>

<script>
import BuysideLogo from '@/../public/svg/BuysideLogo.svg'

export default {
  name: 'buyside_fail',
  components: {
    BuysideLogo
  }
}
</script>
<style lang='scss'>
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';

.buyside_fail_container {
  
  width: 50vw;
  height: 100vh;

  .buyside_fail {
    @include vertical-align();
    
    &::before {
      content: '';
      position: fixed;
      height: 100%;
      width: 100%;
      /* color: black; */
      // background: #a05f5f;
      // clip-path: polygon(99% 100%, 100% 99%, 1% 0%, 0% 1%);
    }

    svg {    
      //clip-path: polygon(0% 100%, 95% 100%, 0% 5%, 0% 0%, 5% 0%, 100% 95%, 100% 0%, 0% 0%);

      .st0{fill:hsl(202, 0%, 52%);}
      .st1{fill:hsl(222, 0%, 28%);}
      .st2{fill:hsl(202, 0%, 52%);}
      //fill: $primary;
      //transition: fill 2s ease-in-out;
    }
  }
}

</style>