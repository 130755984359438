// LOCALLY-DEFINED THEMES

export const makeTheme_CRAZY = () => ({
// == THEME TESTER ==
  // These values are crazy ones for testing purposes,
  //   to clearly showcase what colors/fonts appear where          
  //   and to point out by contrast if any components are missing theme styles
  typography: {
    primary_font:         "'Turret Road'",
    primary_font_URL:     "https://fonts.googleapis.com/css?family=Turret+Road&display=swap",
    secondary_font:       "'Indie Flower'",
    secondary_font_URL:   "https://fonts.googleapis.com/css?family=Indie+Flower&display=swap",
    button_font:          "Tangerine",
    button_font_URL:      "https://fonts.googleapis.com/css?family=Tangerine&display=swap",
    button_weight:        "bolder",
    paragraph_font:       "'Anton'",
    paragraph_font_URL:   "https://fonts.googleapis.com/css?family=Anton&display=swap"
  },
  colors: {
    primary: '#324512',                   //forest green
    primary_font_contrast: '#c49cf1',     //lilac purple
    secondary: '#11ff4f',                 //pastel light green
    secondary_font_contrast: '#0000ff',   //blue
    button: '#FFFF00',                    //yellow
    button_font_contrast: '#00FF00'       //green
  },
  dimensions: {
    button_border_radius: '10px'
  }
});

export const makeTheme_SAGE_DEFAULT = () => ({
  typography: {
    // "*_font":  Valid CSS string with commas delineating fonts to try in sequence. No ending semicolon!
    // "*_font_URL":  Optional. Provide only if the relevant font is not already loaded or available on the site/user's computers.
    //                Must be a URL link to a font stylesheet that can be "@import"ed in CSS.
    primary_font:         "Manrope, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    primary_font_URL:     "https://fonts.googleapis.com/css2?family=Manrope&display=swap",
    secondary_font:       "Marcellus, proxima-nova, sans-serif",
    secondary_font_URL:   "https://fonts.googleapis.com/css2?family=Marcellus&display=swap",
    button_font:          "Manrope, proxima-nova, sans-serif",
    button_font_URL:      "",
    button_weight:        500, //any valid CSS weight. Number or strings like "bolder" work.
    paragraph_font:       "Manrope, proxima-nova, sans-serif",
    paragraph_font_URL:   "https://fonts.googleapis.com/css2?family=Manrope&display=swap",
  },
  colors: {
    // Please provide as 6-digit hex color strings with leading "#"
    // "*_font_contrast":  These are contrast colors, for overlaying text over the related color.
    primary: '#670038',                 // SAGE purple
    primary_font_contrast: '#FFFFFF',   // white
    secondary: '#EAE3D5',               // SAGE tan
    secondary_font_contrast: '#212529', // blackish
    button: '#EAE3D5',                  // SAGE tan
    button_font_contrast: '#212529'     // blackish
  },
  dimensions: {
    button_border_radius: '0px'  //must include trailing "px" or other CSS unit
  }
});

export const makeTheme_GENERIC_DEFAULT = () => ({
  // BELOW ARE THE DEFAULT GENERIC THEME VALUES SHOWN BEFORE CONFIG API RETURNS SPECIFIC THEME DATA
  typography: {
    // "*_font":  Valid CSS string with commas delineating fonts to try in sequence. No ending semicolon!
    // "*_font_URL":  Optional. Provide only if the relevant font is not already loaded or available on the site/user's computers.
    //                Must be a URL link to a font stylesheet that can be "@import"ed in CSS.
    primary_font:         "Work Sans, proxima-nova, sans-serif",
    primary_font_URL:     "https://fonts.googleapis.com/css?family=Work+Sans:700&display=swap",
    secondary_font:       "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    secondary_font_URL:   "",
    button_font:          "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    button_font_URL:      "",
    button_weight:        500, //any valid CSS weight. Number or strings like "bolder" work.
    paragraph_font:       "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    paragraph_font_URL:   "",
  },
  colors: {
    // Please provide as 6-digit hex color strings with leading "#"
    // "*_font_contrast":  These are contrast colors, for overlaying text over the related color.
    primary: '#3F8FC2',                 //default generic light blue
    primary_font_contrast: '#FFFFFF',   //white
    secondary: '#DC4674',               //default generic pink
    secondary_font_contrast: '#FFFFFF', //white
    button: '#DC4674',                  //default generic pink
    button_font_contrast: '#FFFFFF'     //white
  },
  dimensions: {
    button_border_radius: '4px'  //must include trailing "px" or other CSS unit
  }
});