<template>
  <b-modal id="modalClaim" ok-only ok-variant="button" title="CLAIM THIS HOME" refs="modalClaim" class="modalClaim" centered @hide="onHide" @show="onShow">
    <b-container id="innerContainer" class="mx-auto">
      <div>
        <b-row class="claimHomeMessage">
          <b-col cols="24">
            <template v-if="loading">
              <p>Claiming home...</p>
            </template>
            <template v-else-if="success">
              <p>Congratulations! You've claimed your home.</p>
              <p>You have been auto-subscribed to a monthly report.</p>
            </template>
            <template v-else-if="error">
              <p>An error occurred trying to claim your home. Please try again.</p>
            </template>
            <template v-else>
              <p>Claim this home to see more details about this property and automatically signup for monthly email reports.</p>
            </template>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <template #modal-footer v-if="!(success || error)">
      <b-button variant="button" :disabled="loading" :key="loading" @click="claimHome">
        <b-spinner small v-if="loading"></b-spinner>
        <template v-else>Claim Home</template>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ClaimHomeModal',
  
  data: function() {
    return {
      success: false,
      error: false,
      loading: false
    }
  },
  methods: {
    onShow() {
      this.success = false
      this.error = false
      this.loading = false
    },
    onHide(bvModalEvent) {
      if(this.loading === true)
      {
        //don't allow hiding while modal is in loading mode
        bvModalEvent.preventDefault();
        return;
      }
    },
    async claimHome() {
      this.error = false
      this.loading = true
      
      const response = await this.$store.dispatch('user/claimProperty')
      
      if(response && response.status) {
        this.$store.commit('listings/setIsClaimed', true)
        await this.$store.dispatch('user/getUserProperties')
        this.success = true
      } else {
        this.error = true
      }
      this.loading = false
      
    }
  }
}

</script>

<style lang="scss">
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';

#modalClaim {

  .claimHomeMessage {
    p {
      line-height: 1.5;
      font-size: 14px;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }

}

</style>
