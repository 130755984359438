<template>
  <div class='buyside_spinner_container mx-auto text-center'>
    <div class='buyside_spinner'>
      <BuysideLogo />
    </div>
  </div>
</template>

<script>
import BuysideLogo from '@/../public/svg/BuysideLogo.svg'

export default {
  name: 'buyside_spinner',
  components: {
    BuysideLogo
  }
}
</script>
<style lang='scss'>
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';

.buyside_spinner_container {
  
  width: 50vw;
  height: 100vh;

  .buyside_spinner {
    @include vertical-align();

    @keyframes horiz-spin {
      from {
        transform: none
      }
      to {
        transform: rotateY(360deg)
      }
    }

    svg {
      .st0{fill:#4196C8;}
      .st1{fill:#20376D;}
      .st2{fill:#3C98CC;}
      //fill: $primary;
      //transition: fill 2s ease-in-out;
      animation: horiz-spin 1.5s infinite linear;
    }
  }
}

</style>