<template>
  <fragment v-if="blur">
    <div class="blur_section blurred-container">
      <slot></slot>
    </div>
    <b-container fluid class="blur_section blurred-out-public-CTA-div">
      <a class="blurred-out-clickable" @click="launchModal"></a>
      <!-- <div class="CTA-text">
        <a class="btn btn-button" href="#">Claim Your Home Now to See This</a>
      </div> -->
      <slot v-if="hasCustomModal" name="customBlurModal"></slot>
      <b-modal v-else static lazy hide-header centered ok-only ok-variant="button" :id="modalName">
        <div class="text-center" v-text="modalText"></div>
      </b-modal>
    </b-container>
  </fragment>
  <div v-else class="blur_section blur-passthrough">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'blur_section',
  computed: {
    modalName() {
      if (!this.uniqueIDName) return '';

      if (this.hasCustomModal)
        return this.uniqueIDName;
      else
        return 'blurSectionModal_' + this.uniqueIDName;
    },
    hasCustomModal() {
      return !!this.$slots.customBlurModal
    }
  },
  props: {
    uniqueIDName: {
      type: String,
      required: true
    },
    modalText: {
      type: String,
      required: false,
      default: "This content is reserved for agents."
    },
    blur: {
      type: Boolean,
      required: true
    },
    afterRedirectFocusID: {
      type: String,
      required: false
    }
  },
  methods: {
    launchModal(){
      this.checkForOneallModal();
      
      this.$bvModal.show(this.modalName);
    },
    checkForOneallModal() {
      if('modalOneall' == this.modalName && this.afterRedirectFocusID) {
        // This cookie will make sure we can jump in-page to the right section after login callback & reload
        this.$cookies.set('buyside.page2AfterRedirectAnchor', this.afterRedirectFocusID);

        //setup one-time modal close handler, in case user backs out of the modal
        this.$root.$once('bv::modal::hidden', (bvEvent, modalId) => {
          if(modalId === 'modalOneall' && this.$cookies.isKey('buyside.page2AfterRedirectAnchor'))
            this.$cookies.remove('buyside.page2AfterRedirectAnchor');
        })
      }
    }
  }
}
</script>
<style lang='scss'>
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';

.blur_section {
  &.blur-passthrough {
    display: contents;
  }

  &.blurred-container {
    display: contents;
    & > * {
      filter: grayscale(.5) opacity(.5);
      pointer-events: none;

      -webkit-user-select: none;  /* Chrome 49+ */
      -moz-user-select: none;     /* Firefox 43+ */
      -ms-user-select: none;      /* No support yet */
      user-select: none;
    }
  }

  &.blurred-out-public-CTA-div {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      a {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .modal-body {
        padding-top: 2rem;
      }
      .modal-footer {
        border-top: 0
      }
      // .CTA-text {
      //     min-width: 300px;
      //     opacity: 1;
      //     background-color: $borderline;
      //     color: black;
      //     padding: 20px;
      //     border: 1px solid $primary;
      //     border-radius: 5px;
      //     a.btn {
      //         font-size: 16px;
      //         line-height: 1.5;
      //     }

      //     position: absolute;
      //     top: 50%;
      //     left: 50%;
      //     -webkit-transform: translate(-50%, -50%);
      //     transform: translate(-50%, -50%);
      // }
  }
}

</style>
