import api from '@/api'
import {copyIntoRecursive} from '@/store'
import themeGenerator from '@/styles/theme_generator'

//eslint-disable-next-line
import {makeTheme_CRAZY,
        makeTheme_SAGE_DEFAULT,
        makeTheme_GENERIC_DEFAULT} from '@/styles/local_themes'

const getInitialState = function () {
  return {
    brokerage: {
      address: {
        address1: "",
        address2: null,
        city: "",
        state: "",
        zipcode: "",
        lat: null,
        lng: null
      },
      brand_id: null,
      brokerage_id: null,
      default_agent: {
        aboutMe: "",
        agentID: null,
        avatar: "",
        email: "",
        facebookURL: "",
        facebookURL_show: null,
        firstName: "",
        lastName: "",
        licenseNo: null,
        licenseState: null,
        linkedInURL: "",
        linkedInURL_show: null,
        phone_numbers: [],
        profession: "",
        twitterURL: "",
        twitterURL_show: null,
        website: ""
      },
      logo: "",
      logo_url: null,
      name: "",
      short_name: "",
    },
    agent: {
      aboutMe: "",
      agentID: null,
      avatar: "",
      email: "",
      facebookURL: null,
      facebookURL_show: 0,
      firstName: "",
      lastName: "",
      licenseNo: null,
      licenseState: null,
      linkedInURL: null,
      linkedInURL_show: 0,
      office_name: "",
      phone_numbers: [],  // contains objs like this:
                          // {
                          //     "phoneNumber": "(866) 486-5636",
                          //     "phoneType": "Cell"
                          // }
      profession: '',
      twitterURL: null,
      twitterURL_show: 0,
      website: "",
    },
    config: {
      activity_dataset: false,
      address_header: '',
      affiliate_logo: null,
      affiliate_logo_url: null,
      agent_styles: '',
      avm_text: '',
      avm_subtext: '',
      button_hex: '',
      buyer_match_text: '',
      buyer_matches_interval: null,
      buyer_stats_text: '',
      buyer_stats_subtext: '',
      call_to_action_text: '',
      domain: '',
      email_header_logo: '',
      email_template_header_bgcolor: '',
      estimated_values_text: '',
      featured_buyers_text: '',
      fifteen_year_fixed: null,
      funnel_header_text: '',
      funnel_sub_text: '',
      funnel_content1: '',
      funnel_content2: '',
      funnel_content3: '',
      funnel_content4: '',
      funnel_content5: '',
      google_tag_manager_code: '',
      googleanalytics_id: '',
      heatmap_header_text: '',
      heatmap_sub_text: '',
      heatmap_zoom_level: null,
      hide_buyer_activity_funnel: null,
      hide_epw: '',
      lender_logo: '',
      lender_logo_url: '',
      loading_image: '',
      logo: '',
      logo_url: '',
      meta_copyright: '',
      meta_description: '',
      meta_keywords: '',
      meta_title: '',
      og_image_buyer: "",
      ogdescription: "",
      ogimage: "",
      ogsite_name: "",
      ogtitle: "",
      ogtype: "",
      ogurl: "",
      recent_nearby_listings: null,
      recent_sales_nearby: '',
      recent_sales_nearby_text: '',
      results_include_text1: '',
      results_include_text2: '',
      results_include_text3: '',
      results_include_title1: '',
      results_include_title2: '',
      results_include_title3: '',
      s3_folder_name: '',
      sale_proceeds_agent_commissions_fees: '',
      sage_logo: '',
      search_main_caption: '',
      search_subtext: '',
      show_funnel: null,
      show_license_number: null,
      show_market_trends_liveby: null,
      show_market_trends_liveby_brokerage: '',
      show_market_trends_liveby_styles_url: '',
      show_my_equity_component: false,
      show_realtime_users_count: '',
      show_showings_activity: null,
      show_supply_side_trends: null,
      showoff_featured_buyers: null,
      slider_images: [],
      slider_text: '',    //ProperyConditionCTA
      slider_subtext: '', //ProperyConditionCTA
      smartystreets_states_filter: '',
      styles: '',
      supply_side_metricstext1: '',
      supply_side_metricstext2: '',
      supply_side_metricstext3: '',
      supply_side_text: '',
      supply_side_trends_sub_text: '',
      supply_side_subtext: '',
      thirty_year_fixed: null,
      use_google_tag_manager: null,
      whiteout_affiliate_logo: null,
      whiteout_logo: null,
      whole_header_primary_hex_color: null,
      verbiage_text: ''
    },

    // The local theme defined below is shown before the first config API call returns
    // dynamically-driven theme data from Buyside dbs
    theme: (process.env.VUE_APP_CLIENT == "berkshire") ?
              makeTheme_SAGE_DEFAULT() :
              makeTheme_GENERIC_DEFAULT(),

    loadingConfig: true
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    theme (state) {
      // Returning as JSON blob for use in JS theming scenarios
      // This is set to global Vue.prototype.$theme var in main.js
      return state.theme;
    },
    themeCSS (state) {
      return themeGenerator(state.theme, getInitialState().theme, state.config.button_hex);
    },

    loadingConfig (state) {
      return state.loadingConfig
    },

    agentEmail (state) {
      return state.agent.email
    },
    agentFirstName (state) {
      return state.agent.firstName
    },
    agentID (state) {
      return state.agent.agentID || state.brokerage.default_agent.agentID
    },
    agentLicenseNumber (state) {
      return state.agent.licenseNo || '';
    },
    agentMailTo (state) {
      return 'mailto: ' + state.agent.email
    },
    agentMobile (state) {
      if (state.agent.phone_numbers && state.agent.phone_numbers[0]) {
        for (var i = 0; i < state.agent.phone_numbers.length; i++) {
          if (state.agent.phone_numbers[i].phoneType == 'Cell')
            return state.agent.phone_numbers[i].phoneNumber;
        }
        if (state.agent.phone_numbers[0].phoneNumber)
          return state.agent.phone_numbers[0].phoneNumber;
      }
      return '';
    },
    agentMobileLink (state) {
      if (state.agent.phone_numbers && state.agent.phone_numbers[0]) {
        for (var i = 0; i < state.agent.phone_numbers.length; i++) {
          if (state.agent.phone_numbers[i].phoneType == 'Cell')
            return 'tel:' + state.agent.phone_numbers[i].phoneNumber;
        }
        if (state.agent.phone_numbers[0].phoneNumber)
          return 'tel:' + state.agent.phone_numbers[0].phoneNumber;
      }
      return '';
    },
    agentName (state) {
      return state.agent.firstName + ' ' + state.agent.lastName
    },
    agentOfficeName (state) {
      return state.agent.office_name
    },
    agentPhoneNumbers (state) {
      return state.agent.phone_numbers
    },
    agentProfession (state) {
      return state.agent.profession
    },
    agentSocialMedia (state) {
      var socialMediaArray = []
      if(state.agent.facebookURL && state.agent.facebookURL_show) {
        socialMediaArray.push({
          type: 'facebook',
          url: state.agent.facebookURL
        })
      }
      if(state.agent.twitterURL && state.agent.twitterURL_show) {
        socialMediaArray.push({
          type: 'twitter',
          url: state.agent.twitterURL
        })
      }
      if(state.agent.linkedInURL && state.agent.linkedInURL_show) {
        socialMediaArray.push({
          type: 'linkedIn',
          url: state.agent.linkedInURL
        })
      }
      return socialMediaArray
    },
    agentThumbnail (state) {
      if (state.agent.avatar !== '')
        return state.agent.avatar
      else
        return "https://via.placeholder.com/100x130?text=+";
    },
    inFrame (){
      if(window == window.parent){
        return true;
      }
      return false
    },
    agentWebsite (state) {
      return state.agent.website
    },
    brokerageName (state) {
      return state.brokerage.name
    },
    brokerageLogo (state, getters, rootState, rootGetters) {
      if (  rootGetters.mode == "berkshire" //current page is in Berkshire mode
            && 'sage_logo' in state.config
            && !!state.config['sage_logo'] )
      {
        return state.config['sage_logo'];
      }

      return state.brokerage.logo_url;
    },
    brokerageAddress (state) {
      return state.brokerage.address.address1
    },
    brokerageCity (state) {
      return state.brokerage.address.city
    },
    brokerageState (state) {
      return state.brokerage.address.state
    },
    brokerageZipcode (state) {
      return state.brokerage.address.zipcode
    },
    brokerageCityStateZip (state, getters) {
      return getters.brokerageCity + ", " + getters.brokerageState + " " + getters.brokerageZipcode
    },
    funnelContent: (state) => (tier) => {
      return state.config['funnel_content' + tier]
    },
    sliderImages (state) {
      return state.config.slider_images || [];
    }
  },
  mutations: {
    _resetState(state) {
      copyIntoRecursive(state, getInitialState())
    },
    setConfig (state, value) {
      copyIntoRecursive(state, value);
    },
    loadingConfig(state, value) {
      state.loadingConfig = value
    },
    setTheme (state, value) {
      copyIntoRecursive(state.theme, value);
    },
    setThemeSparse (state, value) {
      copyIntoRecursive(state.theme, value, false);
    },
  },
  actions: {
    getConfig ({ commit }, params) {
      commit('loadingConfig', true)
      return api
        .getConfig(params)
        .then(function (config) {
          // Local Theme Overrides (FOR TESTING)
          //   Uncomment one of the lines below to override the API theme
          //   with a locally-defined tester theme

          // commit('setTheme', makeTheme_GENERIC_DEFAULT())
          // commit('setTheme', makeTheme_SAGE_DEFAULT())
          // commit('setTheme', makeTheme_CRAZY())
          
          if( config.config.vanity_url == 1 && config.agent.vanity_url != '' &&  window == window.parent){
            let old_url = new URL(window.location)
            let url = config.agent.vanity_url[0] + old_url.pathname + old_url.search;
            
            window.location.href = url;
          }

          return config
        })
        .catch( e => {
          commit('setFailure', true, {root: true})
          throw e
        })
        .finally( () => {
          commit('loadingConfig', false)
        })
    }
  }
}
