// oneall injection
(function(): void {
  // Inject oneall
  const oneall_subdomain = 'findbuyers';

  /* The library is loaded asynchronously */
  const oa = document.createElement('script');
  oa.type = 'text/javascript';
  oa.async = true;
  oa.src = '//' + oneall_subdomain + '.api.oneall.com/socialize/library.js';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode?.insertBefore(oa, s);

  /* Initialise the asynchronous queue */
  // eslint-disable-next-line
  var _oneall: any = _oneall || [];  // assigning to global scope

  // End inject oneall
})();

// Iframe Content injection
(function(): void {
  // Inject Iframe Content

  /* The library is loaded asynchronously */
  const iframe_content = document.createElement('script');
  iframe_content.type = 'text/javascript';
  iframe_content.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.1.1/iframeResizer.contentWindow.min.js';
  iframe_content.crossOrigin = 'anonymous';
  iframe_content.referrerPolicy = 'no-referrer'
  const s = document.getElementsByTagName('script')[0];
  s.parentNode?.insertBefore(iframe_content, s);

  /* Initialise the asynchronous queue */
  // eslint-disable-next-line
  var _iframecontent: any = _iframecontent || [];  // assigning to global scope

  // End inject Iframe Content
})();

// jquery injection
declare global {
  interface Window {
      $: any;
      JQuery: any;
      vm?: any;
  }
}
window.$ = window.JQuery = require('jquery')

// import hooks type to enable auto-complete
import 'vue-class-component/hooks'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './filters'

// Set theme information from Config API to $theme
Vue.prototype.$theme = store.getters['config/theme'];

// Setup router navigation guard to always start spinner again on page navigation (Generic only)
router.beforeEach((to, from, next) => {
  if(!to.meta.isSAGE)
    store.commit('setSpinner', true);
  next();
})

/********* Font Awesome icons *******/

  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faSearch, faStar, faArrowRight, faInfoCircle, faArrowAltCircleUp, faArrowAltCircleDown, faArrowLeft, faExclamationCircle, faCheckCircle, faTimesCircle, faUser, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
  import { faCalendarAlt, faEnvelope, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
  import { faTwitter, faFacebookF, faInstagram, faYoutube, faLinkedinIn, faPinterestP } from '@fortawesome/free-brands-svg-icons'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  library.add(faTwitter, faFacebookF, faInstagram, faYoutube, faLinkedinIn, faPinterestP, faSearch, faCalendarAlt, faArrowLeft,
    faStar, faArrowRight, faInfoCircle, faExclamationCircle, faArrowAltCircleUp, faArrowAltCircleDown, faEnvelope, faQuestionCircle,
    faCheckCircle, faTimesCircle, faUser, faPhoneAlt)

  Vue.component('font-awesome-icon', FontAwesomeIcon)

/********* END Font Awesome icons *******/


// Plugins

import BFormSlider from 'vue-bootstrap-slider'
import BootstrapVue from 'bootstrap-vue'
import CheckView from 'vue-check-view'
import Fragment from "vue-fragment"
import HighchartsVue from 'highcharts-vue'
import LineClamp from "vue-line-clamp"
import PortalVue from 'portal-vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueLazyLoad from 'vue-lazyload'
import VueMoment from 'vue-moment'
  import moment from 'moment'
import VueObserveVisibility from 'vue-observe-visibility'
import VueMq from 'vue-mq'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'

// For testing purposes, uncomment this:

// import VueGtag from "vue-gtag";

// Vue.use(VueGtag, {
//   config: { id: "UA-101976462-1" }, // UA-101976462-1 is the real Buyside tracker, use UA-216802834-1 to test (Rob K)
//   globalObjectName: 'buyside_gtag',
//   globalDataLayerName: 'buyside_datalayer',
// });

Vue.use(BFormSlider)
Vue.use(BootstrapVue)
Vue.use(CheckView)
Vue.use(Fragment.Plugin)
Vue.use(HighchartsVue)
Vue.use(LineClamp)
Vue.use(PortalVue)
Vue.use(VueLazyLoad)
Vue.use(VueMoment, {moment})
Vue.use(VueObserveVisibility)
Vue.use(VueCookies)
Vue.use(VueMeta)
Vue.use(VueMq, {
  breakpoints: {
    xs: 768,  //keep these the same as in _custom-variables.scss
    sm: 992,
    md: 1200,
    lg: 1440,
    xl: Infinity
  }
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyASLd0x2iEVZpo9r0NBC8beqN_YRFRm_dM',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})


// Miscellaneous

Vue.config.productionTip = false

import Highcharts from 'highcharts';
Highcharts.setOptions ({
  lang: {
        decimalPoint: '.',
        thousandsSep: ','
  },
  time: {
    useUTC: false
  }
});


import MobileDetect from 'mobile-detect'

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    isMobile?: boolean
  }
}

Vue.mixin({
  created () {
    const device = new MobileDetect(window.navigator.userAgent);

    (this as Vue).$options.isMobile = device.mobile() !== null
  }
})


// Global components

import BuysideSpinner   from '@/components/loaders/BuysideSpinner.vue'
import BuysideFail      from '@/components/loaders/BuysideFail.vue'
import GraphLoader      from '@/components/loaders/Graph.vue'
import SectionLoader    from '@/components/loaders/SectionText.vue'
import BlurSection      from '@/components/shared/BlurSection.vue'
import BImgFallback     from '@/components/shared/BImgFallback.vue'

Vue.component('VBuysideSpinner',  BuysideSpinner)
Vue.component('VBuysideFail',     BuysideFail)
Vue.component('VGraphLoader',     GraphLoader)
Vue.component('VSectionLoader',   SectionLoader)
Vue.component('VBlurSection',     BlurSection)
Vue.component('VBImgFallback',    BImgFallback)


//Initialize Growthbook
const featuresEndpoint = process.env.VUE_APP_GROWTHBOOK_ENDPOINT;
store.dispatch('growthbook/getFeaturesFlags', { featuresEndpoint, enableDevMode: true });

// Instantiate Vue component
const vm = new Vue({
  router,
  template: '<App/>',
  components: { App },
  store
})

router.onReady(() => {
  vm.$mount('#app')
  if(process.env.VUE_APP_NODE_ENV === "development") window.vm = vm
})
