import api from '@/api'
import { GrowthBook } from "@growthbook/growthbook";

const getInitialState = () => {
  return {
    growthBook: null,
    featuresLoaded: false,
  };
};

export default{
    namespaced: true,
    state: getInitialState(),
    getters: {
        isFeatureEnabled: (state) => (feature) => {
            return state.growthBook ? state.growthBook.feature(feature).on : false;
        },
    },
    mutations: {
        setGrowthBook(state, value) {
            state.growthBook = value;
        },
    },
    actions: {
        async getFeaturesFlags({ commit }, { featuresEndpoint, enableDevMode = false }) {
            try {
                const json = await api.getFeaturesJson(featuresEndpoint);

                const growthBook = new GrowthBook({
                    features: json.features,
                    enableDevMode,
                });

                commit('setGrowthBook', growthBook);
            } catch (error) {
                console.error("GrowthBook initialization error", error);
            }
        }
    },
}