<template>
  <div class="col-22 mt-5 offset-2">
    <content-loader
      :height="160"
      :width="400"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="70" height="20" />
      <rect x="15" y="40" rx="3" ry="3" width="130" height="10" />
      <rect x="155" y="40" rx="3" ry="3" width="130" height="10" />
      <rect x="15" y="60" rx="3" ry="3" width="90" height="10" />
      <rect x="115" y="60" rx="3" ry="3" width="60" height="10" />
      <rect x="185" y="60" rx="3" ry="3" width="60" height="10" />
      <rect x="0" y="80" rx="3" ry="3" width="30" height="10" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  name: "section-text-loader",
  components: {
    ContentLoader
  }
};
</script>
