/*

  Definitions of Offer Optimizer data & calculations

*/

//helper function
// eslint-disable-next-line
function assertHasKey<O>(obj: O, key: keyof any): asserts key is keyof O {
  if (!(key in obj)) throw new Error('Key is not in object')
}

export enum eLocation {
  Charlotte = 'Charlotte',
  Raleigh = 'Raleigh'
}
type tLocation = keyof typeof eLocation

export enum eCondition {
  MinorRepairs = 'MinorRepairs',
  Excellent = 'Excellent'
}
type tCondition = keyof typeof eCondition

export enum eSource {
  ClientBrokerage = 'ClientBrokerage',
  Zillow = 'Zillow',
  OpenDoor = 'OpenDoor',
  Offerpad = 'Offerpad'
}
type tSource = keyof typeof eSource

type Percentage = number
type NumberRange = [number, number?]

export type tOfferInfo = {
  sellerAgentCommission: Percentage;
  prepAndRepairCosts: Percentage; // based on Condition
  totalInspections: number;
  totalShowings: NumberRange;
  priceNegotiable: boolean;
  agreePrepRepairCosts: Percentage;
  estimatedOffer: Percentage; // based on Location
  serviceFee: Percentage; // based on Location
  buyerAgentCommission: Percentage; // based on Location
  closingCosts: Percentage; // based on Location
  daysToClose: NumberRange; // based on Location
  receiveOfferEligibility: Percentage; // based on Location
  acceptsInitialOffer: Percentage; // based on Location
  likelihoodOfSale: Percentage; // based on Location

  costOfSelling?: Percentage; //calculated from sibling values
  estimatedNet?: Percentage;  //calculated from sibling values
  moneyOnTable?: Percentage;  //calculated from sibling values
}

export function getCostOfSelling(obj: tOfferInfo): number {
  return (
    obj.serviceFee + 
    obj.sellerAgentCommission + 
    obj.buyerAgentCommission + 
    obj.prepAndRepairCosts + 
    obj.closingCosts
  )
}

const percentageKeys: (keyof tOfferInfo)[] = [
  'serviceFee',
  'sellerAgentCommission',
  'buyerAgentCommission',
  'prepAndRepairCosts',
  'closingCosts',
  // not including estimatedOffer which we will handle separately
]

type tOfferDataMatrix = {
  [S in tSource]: tOfferInfo
}

export function getOptimizedOfferPercentages(location: eLocation, condition: eCondition): tOfferDataMatrix {
  return _getOptimizedOffers(location, condition, undefined)
}

export function getOptimizedOffers(
  location: eLocation,
  condition: eCondition,
  valuation: number
): tOfferDataMatrix {
  return _getOptimizedOffers(location, condition, valuation)
}

function _getOptimizedOffers(
  location: eLocation,
  condition: eCondition,
  valuation?: number
): tOfferDataMatrix {
  const returnMatrix = {} as tOfferDataMatrix //Apparently we need a type assertion here to make this compile w/o errors

  let sourceStr: tSource

  for (sourceStr in eSource) {
    assertHasKey(eSource, sourceStr)

    const source: tSource = eSource[sourceStr] as tSource

    assertHasKey(BreakdownBySource, source)
    assertHasKey(BreakdownBySourceAndLocation, source)
    assertHasKey(BreakdownBySourceAndCondition, source)

    const offerForThisSource: tOfferInfo = {
      ...BreakdownBySource[source],
      ...BreakdownBySourceAndLocation[source][location],
      ...BreakdownBySourceAndCondition[source][condition]
    }

    if (valuation) {
      const estimatedOffer = (offerForThisSource.estimatedOffer *= valuation)

      // prettier-ignore
      for (const key of percentageKeys) {
        (offerForThisSource[key] as number) *= estimatedOffer     //assert that these are all numeric properites of offerForThisSource
      }

      // handle special logic
      offerForThisSource.costOfSelling = getCostOfSelling(offerForThisSource)
      offerForThisSource.estimatedNet = estimatedOffer - offerForThisSource.costOfSelling
    }

    returnMatrix[source] = offerForThisSource
  }

  //handle special logic
  if(valuation) {
    for (sourceStr in eSource) {
      assertHasKey(eSource, sourceStr)
  
      const source: tSource = eSource[sourceStr] as tSource

      returnMatrix[source].moneyOnTable = 
        (returnMatrix[eSource.ClientBrokerage].estimatedNet || 0) -
        (returnMatrix[source].estimatedNet || 0)
    }
  }

  return returnMatrix
}

type tBreakdownBySource = {
  [S in tSource]: {
    sellerAgentCommission: Percentage;
    totalInspections: number;
    totalShowings: NumberRange;
    priceNegotiable: boolean;
    agreePrepRepairCosts: Percentage;
  }
}

const BreakdownBySource: tBreakdownBySource = {
  [eSource.ClientBrokerage]: {
    sellerAgentCommission: 0.03,
    totalInspections: 1,
    totalShowings: [1, 30],
    priceNegotiable: true,
    agreePrepRepairCosts: 0.95
  },
  [eSource.Zillow]: {
    sellerAgentCommission: 0.02,
    totalInspections: 3,
    totalShowings: [0],
    priceNegotiable: false,
    agreePrepRepairCosts: 0.8
  },
  [eSource.OpenDoor]: {
    sellerAgentCommission: 0.02,
    totalInspections: 3,
    totalShowings: [0],
    priceNegotiable: false,
    agreePrepRepairCosts: 0.85
  },
  [eSource.Offerpad]: {
    sellerAgentCommission: 0.02,
    totalInspections: 2,
    totalShowings: [0],
    priceNegotiable: false,
    agreePrepRepairCosts: 0.85
  }
}

type tBreakdownBySourceAndCondition = {
  [S in tSource]: {
    [C in tCondition]: {
      prepAndRepairCosts: Percentage;
    }
  }
}

const BreakdownBySourceAndCondition: tBreakdownBySourceAndCondition = {
  [eSource.ClientBrokerage]: {
    [eCondition.MinorRepairs]: { prepAndRepairCosts: 0.012 },
    [eCondition.Excellent]: { prepAndRepairCosts: 0.003 }
  },
  [eSource.Zillow]: {
    [eCondition.MinorRepairs]: { prepAndRepairCosts: 0.038 },
    [eCondition.Excellent]: { prepAndRepairCosts: 0.0095 }
  },
  [eSource.OpenDoor]: {
    [eCondition.MinorRepairs]: { prepAndRepairCosts: 0.029 },
    [eCondition.Excellent]: { prepAndRepairCosts: 0.00725 }
  },
  [eSource.Offerpad]: {
    [eCondition.MinorRepairs]: { prepAndRepairCosts: 0.044 },
    [eCondition.Excellent]: { prepAndRepairCosts: 0.011 }
  }
}

type tBreakdownBySourceAndLocation = {
  [S in tSource]: {
    [L in tLocation]: {
      estimatedOffer: Percentage;
      serviceFee: Percentage;
      buyerAgentCommission: Percentage;
      closingCosts: Percentage;
      daysToClose: NumberRange;
      receiveOfferEligibility: Percentage;
      acceptsInitialOffer: Percentage;
      likelihoodOfSale: Percentage;
    }
  }
}

export const BreakdownBySourceAndLocation: tBreakdownBySourceAndLocation = {
  [eSource.ClientBrokerage]: {
    [eLocation.Charlotte]: {
      estimatedOffer: 1,
      serviceFee: 0,
      buyerAgentCommission: 0.03,
      closingCosts: 0.013,
      daysToClose: [57],
      receiveOfferEligibility: 0.93,
      acceptsInitialOffer: 0.89,
      likelihoodOfSale: 0.79
    },
    [eLocation.Raleigh]: {
      estimatedOffer: 1,
      serviceFee: 0,
      buyerAgentCommission: 0.024,
      closingCosts: 0.015,
      daysToClose: [48],
      receiveOfferEligibility: 0.89,
      acceptsInitialOffer: 0.94,
      likelihoodOfSale: 0.79
    }
  },
  [eSource.Zillow]: {
    [eLocation.Charlotte]: {
      estimatedOffer: 0.9817,
      serviceFee: 0.076256,
      buyerAgentCommission: 0,
      closingCosts: 0,
      daysToClose: [7, 90],
      receiveOfferEligibility: 0.31,
      acceptsInitialOffer: 0.12,
      likelihoodOfSale: 0.03
    },
    [eLocation.Raleigh]: {
      estimatedOffer: 0.9878,
      serviceFee: 0.075,
      buyerAgentCommission: 0,
      closingCosts: 0,
      daysToClose: [7, 90],
      receiveOfferEligibility: 0.33,
      acceptsInitialOffer: 0.12,
      likelihoodOfSale: 0.03
    }
  },
  [eSource.OpenDoor]: {
    [eLocation.Charlotte]: {
      estimatedOffer: 0.9784,
      serviceFee: 0.074,
      buyerAgentCommission: 0,
      closingCosts: 0.01229888,
      daysToClose: [10, 60],
      receiveOfferEligibility: 0.28,
      acceptsInitialOffer: 0.35,
      likelihoodOfSale: 0.08
    },
    [eLocation.Raleigh]: {
      estimatedOffer: 0.9851,
      serviceFee: 0.079,
      buyerAgentCommission: 0,
      closingCosts: 0.01229888,
      daysToClose: [10, 60],
      receiveOfferEligibility: 0.34,
      acceptsInitialOffer: 0.35,
      likelihoodOfSale: 0.1
    }
  },
  [eSource.Offerpad]: {
    [eLocation.Charlotte]: {
      estimatedOffer: 0.9304,
      serviceFee: 0.075,
      buyerAgentCommission: 0,
      closingCosts: 0.015498888,
      daysToClose: [8, 90],
      receiveOfferEligibility: 0.3,
      acceptsInitialOffer: 0.33,
      likelihoodOfSale: 0.08
    },
    [eLocation.Raleigh]: {
      estimatedOffer: 0.9287,
      serviceFee: 0.075,
      buyerAgentCommission: 0,
      closingCosts: 0.012,
      daysToClose: [8, 90],
      receiveOfferEligibility: 0.36,
      acceptsInitialOffer: 0.35,
      likelihoodOfSale: 0.11
    }
  }
}

// prettier-ignore
export const Charlotte_ZipCodes = [
  28006,28010,28012,28016,28017,28020,28021,28026,28027,28031,28032,28033,28033,28034,28035,28036,28037,28038,28042,28052,28053,28054,28055,28056,28070,28073,28075,28077,28078,28079,28080,28081,28082,28083,28086,28089,28090,28092,28093,28098,28101,28103,28104,28105,28106,28107,28108,28110,28111,28112,28114,28115,28117,28120,28123,28124,28126,28130,28134,28136,28150,28151,28152,28164,28166,28168,28169,28173,28174,28201,28202,28203,28204,28205,28206,28207,28208,28209,28210,28211,28212,28213,28214,28215,28216,28217,28218,28219,28220,28221,28222,28223,28224,28226,28227,28228,28229,28230,28231,28232,28233,28234,28235,28236,28237,28241,28242,28243,28244,28246,28247,28250,28253,28254,28255,28256,28258,28260,28262,28263,28265,28266,28269,28270,28271,28272,28273,28274,28275,28277,28278,28280,28281,28282,28284,28285,28287,28288,28289,28290,28296,28297,28299,28625,28634,28660,28677,28687,28688,28689,28699,29058,29067,29703,29704,29708,29710,29715,29716,29717,29720,29721,29722,29726,29730,29731,29732,29733,29734,29742,29743,29744,29745
]

// prettier-ignore
export const Raleigh_ZipCodes = [
  27207,27208,27213,27228,27231,27237,27243,27252,27256,27278,27312,27330,27331,27332,27343,27344,27502,27503,27504,27505,27507,27508,27509,27510,27511,27512,27513,27514,27515,27516,27517,27518,27519,27520,27522,27523,27524,27525,27526,27527,27528,27529,27539,27540,27541,27542,27545,27549,27551,27555,27559,27560,27562,27563,27564,27565,27568,27569,27570,27571,27572,27573,27574,27576,27577,27581,27582,27583,27586,27587,27588,27589,27591,27592,27593,27594,27596,27597,27599,27601,27602,27603,27604,27605,27606,27607,27608,27609,27610,27611,27612,27613,27614,27615,27616,27617,27619,27620,27621,27622,27623,27624,27625,27626,27627,27628,27629,27634,27635,27636,27640,27650,27656,27658,27661,27668,27675,27676,27690,27695,27697,27698,27699,27701,27702,27703,27704,27705,27706,27707,27708,27709,27709,27710,27711,27712,27713,27715,27717,27722,28355
]
