/**
 *    Safe, recursive copying function for resetting Vuex states.
 *    Only copies items from src into dest if already defined in dest.
 *    Overwrite-copies arrays, but recursively copies props of nested objects.
 *    This is needed so Vuex does not lose its tracking references for state vars.
 *    Shallow copying a multi-depth object (a.k.a. overstamping it) could cause
 *      Vuex to lose track of nested state variables and mess up reactivity in app.
 *
 *    Will not attempt to overwrite frozen dest objects, even shallowly frozen ones.
 *
 *    By default, falsySrcValuesOverwrite == true. So values like null, 0, "" in src
 *      will overwrite their corresponding properties in dest, a.k.a. they are
 *      purposefully stamping out the dest data. If the flag is false, falsy values in
 *      src are treated as if the datum is not provided and will not overwrite dest props.
 */
export function copyIntoRecursive (
  // use "never" when instantiating generic type parameters
  // that you don't need to know the type of
  dest: Record<string, never> = {},
  src: Record<string, never> = {},
  falsySrcValuesOverwrite = true
):  Record<string, never> {
  if(Object.isFrozen(dest)) return dest;

  for(const key of Object.keys(dest))
  {
    if(!Object.prototype.hasOwnProperty.call(src, key)) continue;

    if(
        dest[key] &&
        src[key] &&
        typeof dest[key] == "object" &&
        typeof src[key] == "object" &&
        !Array.isArray(dest[key]) &&
        !Array.isArray(src[key])
      )
    {
      if(!Object.isFrozen(dest[key])) {
        //recursively copy nested objects
        copyIntoRecursive (dest[key], src[key], falsySrcValuesOverwrite)
      }
    }
    else {
      if(falsySrcValuesOverwrite || src[key])
        dest[key] = src[key];
    }
  }
  return dest;
}


export type TPendingPromise<T> = Promise<T> & {
  resolve: (value: unknown) => void;
  reject: (reason?: unknown) => void;
}

// https://lea.verou.me/2016/12/resolve-promises-externally-with-this-one-weird-trick/
export function makePendingPromise(): TPendingPromise<unknown> {
	let res, rej;

	const pendingPromise: Partial<TPendingPromise<unknown>> = new Promise(
    (resolve, reject) => {
      res = resolve;
      rej = reject;
	});

	pendingPromise.resolve = res;
	pendingPromise.reject = rej;

	return pendingPromise as TPendingPromise<unknown>
}


export function isNotNull<T> (arg: T): arg is Exclude<T, null> {
  return arg !== null
}


import router from '@/router'
/**
 * This is a safe method for getting query param values from Vue Router
 * that returns either a query parameter value or `undefined`
 * if a value doesn't exist. (i.e. in `?a&b=2`, `a` has no value.)
 *
 * In Vue Router, the query object could have values of either `string` or
 * `(string | null)[]` type, if a query param appears multiple times in a URL.
 * In the latter case, this will return the latest (right-most) value for a given
 * key that has a non-null value.
 *
 * @param queryParamName The query parameter to look at
 */
export function fetchFromQueryParam(queryParamName: string): string | undefined {
  const maybeParam = router.currentRoute.query[queryParamName]
  if(maybeParam) {
    if(Array.isArray(maybeParam)){
      const lastItem = maybeParam.filter( isNotNull ).pop()
      if(lastItem)
        return lastItem
    }
    else return maybeParam
  }
}