import api from '@/api'
import {copyIntoRecursive,
        default_getter as get,
        default_setter as set} from '@/store'

const getInitialState = function () {
  return {
    buyers: {}, //including paging data
    loadingBuyers: true,

    featuredBuyers: {}, // New endpoint for featured buyers cards on page 2
    loadingFeaturedBuyers: true,

    buyer: {},
    loadingBuyer: true,

    funnel: {},
    loadingFunnel: true,

    stateFunnel: {},
    loadingStateFunnel: true,

    bloopObj: [],
    loadingBloopObj: true,

    //used for BuyerStats charts
    buyersByPrice: [],
    buyersByBedroom: [],
    buyersByLocation: [],
    loadingBuyersByPrice: true,
    loadingBuyersByBedroom: true,
    loadingBuyersByLocation: true,

    //used for Buyer Heatmap
    buyerHeatmapGeoJSON: {},
    loadingBuyerHeatmapGeoJSON: true,

    //used for Buyer Heatmap
    buyerSearchAreaGeoJSON: {},
    loadingBuyerSearchAreaGeoJSON: true
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    // Loading section:
    loadingBuyers:                    get('loadingBuyers'),
    loadingFeaturedBuyers:            get('loadingFeaturedBuyers'),
    loadingBuyer:                     get('loadingBuyer'),
    loadingFunnel:                    get('loadingFunnel'),
    loadingStateFunnel:               get('loadingStateFunnel'),
    loadingBuyersByPrice:             get('loadingBuyersByPrice'),
    loadingBuyersByBedroom:           get('loadingBuyersByBedroom'),
    loadingBuyersByLocation:          get('loadingBuyersByLocation'),
    loadingBuyerHeatmapGeoJSON:       get('loadingBuyerHeatmapGeoJSON'),
    loadingBuyerSearchAreaGeoJSON:    get('loadingBuyerSearchAreaGeoJSON'),
    loadingBloopObj:                  get('loadingBloopObj'),
    // End Loading

    buyers:         get('buyers'),
    featuredBuyers: get('featuredBuyers'),
    buyer:          get('buyer'),
    getBloopObj:    get('bloopObj'),

    purchaseTiming: (state) => (moment) => {
      const time = state.buyer.target_purchase_date // formatted -> 2019-06-30
			const targetTime = moment(time, "YYYY-MM-DD")
			const today = moment()
			const sixMonths = moment(today).add(6, 'months').format("YYYY-MM-DD")
      const ninetyDays = moment(today).add(90, 'days').format("YYYY-MM-DD")

			return targetTime.isBefore(today) ? "ASAP"
      : targetTime.isBefore(ninetyDays) ? "Within 90 Days"
      : targetTime.isBefore(sixMonths) ? "Within Next 6 Months"
      : "After 6 Months"
    },
    funnel (state) {
      var vals = Object.keys(state.funnel);
      var retVal = [];

      if (vals.length) {
        for (var x = 0; x < vals.length; x++) {
          retVal.push(state.funnel[vals[x]]);
        }
      }
      return retVal;
    },
    stateFunnel (state) {
      return Object.values(state.stateFunnel).map(x => +x)
    },
    buyersByPrice (state) {
      // This will be returned in a format that HighCharts can ingest
      let array = []
      const sourceAsArray = Array.isArray(state.buyersByPrice) ? state.buyersByPrice : []
      sourceAsArray.forEach(function(step) {
        if(step.count >= 0)
          array.push(
            {
              name: step.display_name,
              y: step.count
            }
          )
      })
      return array;
    },
    buyersByBedroom: (state) => (colorRangeFn) => {
      // This will be returned in a format that HighCharts can ingest
      let array = [];
      const total = state.buyersByBedroom.length
      const colors = colorRangeFn(total)

      const sourceAsArray = Array.isArray(state.buyersByBedroom) ? state.buyersByBedroom : []
      sourceAsArray.forEach(function(step, index) {
        array.push({
          name: step.display_name,
          y: step.count,
          color: colors[index]
        })
      })
      return array;
    },
    buyersByLocation (state) {
      // This will be returned in a format that HighCharts can ingest

      const sourceAsArray = Array.isArray(state.buyersByLocation) ? state.buyersByLocation : []

      const groupedByCityName = sourceAsArray
        .reduce((obj, cur) => {
            if(typeof cur === 'object' && typeof cur?.cityStName === 'string')
              obj[cur.cityStName] = (obj[cur.cityStName] || 0) + Number(cur?.count || 0)
            return obj
          }, {})

      return Object.entries(groupedByCityName)
        .sort((a,b) => b[1] - a[1])       //sort highest to lowest
        .slice(0,10)                      // TODO: remove limit of 10? discuss w business
        .map(x => ({name: x[0], y: x[1]}));
    },
    buyerHeatmapGeoJSON (state) {
      return state.buyerHeatmapGeoJSON;
    },
    buyerSearchAreaGeoJSON (state) {
      return state.buyerSearchAreaGeoJSON;
    },

    /* Buyer by ID Data */
    beds (state) {
      if (state.buyer.beds !== null) {
        return state.buyer.beds
      }
      return false
    },
    baths (state) {
      if (state.buyer.baths !== null) {
        return state.buyer.baths
      }
      return false
    },
    financials (state) {
      var financials = []
      if(state.buyer.price_low) {
        financials.push(
          {label: "Minimum Price", value: state.buyer.price_low, format: 'currency'}
        )
      }
      if(state.buyer.price_high) {
        financials.push(
          {label: "Maximum Price", value: state.buyer.price_high, format: 'currency'}
        )
      }

      return financials
    },
    desiredFeatures (state) {
      var features = []
      if(state.buyer.property_types && state.buyer.property_types.length) {
        features.push(
          {label: 'Property Type', value: state.buyer.property_types}
        )
      }
      if(state.buyer.beds) {
        features.push(
          {label: 'Bedrooms', value: state.buyer.beds + '+'}
        )
      }
      if(state.buyer.baths) {
        features.push(
          {label: 'Bathrooms', value: state.buyer.baths + '+'}
        )
      }
      return features
    },
    desiredLocations (state) {
      var locationTypes = []
      if(state.buyer.townships && state.buyer.townships.length) {
        locationTypes.push(
          {label: 'Townships', value: state.buyer.townships}
        )
      }
      if(state.buyer.neighborhoods && state.buyer.neighborhoods.length) {
        locationTypes.push(
          {label: 'Neighborhoods', value: state.buyer.neighborhoods}
        )
      }
      if(state.buyer.school_districts && state.buyer.school_districts.length) {
        var districts = []
        state.buyer.school_districts.forEach(function(district) {
          districts.push( district.state ?
            district.name + ', ' + district.state : district.name )
        })
        locationTypes.push(
          {label: 'School Districts', value: districts}
        )
      }
      return locationTypes
    },
  },
  mutations: {
    _resetState(state) {
      copyIntoRecursive(state, getInitialState())
    },

    setBuyers:                  set('buyers'),
    setFeaturedBuyers:          set('featuredBuyers'),
    setBuyer:                   set('buyer'),
    setFunnel:                  set('funnel'),
    setStateFunnel:             set('stateFunnel'),
    setBuyerHeatmapGeoJSON:     set('buyerHeatmapGeoJSON'),
    setBuyerSearchAreaGeoJSON:  set('buyerSearchAreaGeoJSON'),
    setBloopObj:                set('bloopObj'),

    setBuyersByBedroom (state, value) {
      if('by_brokerage' in value) {
        state.buyersByBedroom = value.by_brokerage
      }
      else if('by_brand' in value) {
        state.buyersByBedroom = value.by_brand
      }
    },
    setBuyersByBedroom__state (state, value) {
      if(Array.isArray(value))
      {
        const hasTotalField = value.findIndex( item => item.display_name === "Total activities" )

        if(~hasTotalField) {
          value.splice(hasTotalField, 1) //remove the total activities field if it exists
        }

        value = value.filter(item => item.count > 0)
      }

      state.buyersByBedroom = value
    },
    setBuyersByPrice (state, value) {
      if('by_brokerage' in value) {
        state.buyersByPrice = value.by_brokerage
      }
      else if('by_brand' in value) {
        state.buyersByPrice = value.by_brand
      }
    },
    setBuyersByPrice__state (state, value) {
      if(Array.isArray(value))
      {
        value.shift() // remove the first item in the array which is just a total count

        value = value.filter(item => item.count > 0)
      }

      state.buyersByPrice = value
    },
    setBuyersByLocation (state, value) {
      if('by_brokerage' in value) {
        state.buyersByLocation = value.by_brokerage
      }
      else if('by_brand' in value) {
        state.buyersByLocation = value.by_brand
      }
    },

    // loaders
    loadingBuyers:                    set('loadingBuyers'),
    loadingFeaturedBuyers:            set('loadingFeaturedBuyers'),
    loadingBuyer:                     set('loadingBuyer'),
    loadingFunnel:                    set('loadingFunnel'),
    loadingStateFunnel:               set('loadingStateFunnel'),
    loadingBuyersByBedroom:           set('loadingBuyersByBedroom'),
    loadingBuyersByPrice:             set('loadingBuyersByPrice'),
    loadingBuyersByLocation:          set('loadingBuyersByLocation'),
    loadingBuyerHeatmapGeoJSON:       set('loadingBuyerHeatmapGeoJSON'),
    loadingBuyerSearchAreaGeoJSON:    set('loadingBuyerSearchAreaGeoJSON'),
    loadingBloopObj:                  set('loadingBloopObj'),

  },
  actions: {
    getBuyers ({ commit }) {
      commit('loadingBuyers', true)
      return api
        .getBuyers()
        .then(function (buyers) {
          commit('setBuyers', buyers)
        })
        .finally(() =>
          commit('loadingBuyers', false)
        )
    },
    getFeaturedBuyers ({ commit }, address) {
      commit('loadingFeaturedBuyers', true)
      return api
        .getFeaturedBuyers(address)
        .finally(() =>
          commit('loadingFeaturedBuyers', false)
        )
    },
    getBuyer ({ commit }, buyerID) {
      commit('loadingBuyer', true)
      return api
        .getBuyer(buyerID)
        .finally(()=>
          commit('loadingBuyer', false)
        )
    },
    getFunnel ({ commit }, payload) {
      commit('loadingFunnel', true)
      return api
        .getFunnel(payload)
        .then(function (funnel) {
          return funnel
          //commit('setFunnel', funnel)
        })
        .finally(() =>
          commit('loadingFunnel', false)
        )
    },
    getStateFunnel ({ commit }, payload) {
      commit('loadingStateFunnel', true)
      return api
        .getStateFunnel(payload)
        .finally(() =>
          commit('loadingStateFunnel', false)
        )
    },
    getBuyersByBedroom ({ commit }, address) {
      commit('loadingBuyersByBedroom', true)
      return api
        .getBuyersByBedroom(address)
        .finally(() =>
          commit('loadingBuyersByBedroom', false)
        )
    },
    getBuyersByBedroom__state ({ commit }, address) {
      commit('loadingBuyersByBedroom', true)
      return api
        .getBuyersByBedroom__state(address)
        .finally(() =>
          commit('loadingBuyersByBedroom', false)
        )
    },
    getBuyersByPrice ({ commit }, address) {
      commit('loadingBuyersByPrice', true)
      return api
        .getBuyersByPrice(address)
        .finally(() =>
          commit('loadingBuyersByPrice', false)
        )
    },
    getBuyersByPrice__state ({ commit }, address) {
      commit('loadingBuyersByPrice', true)
      return api
        .getBuyersByPrice__state(address)
        .finally(() =>
          commit('loadingBuyersByPrice', false)
        )
    },
    getBuyersByLocation ({ commit }, address) {
      commit('loadingBuyersByLocation', true)
      return api
        .getBuyersByLocation(address)
        .finally(() =>
          commit('loadingBuyersByLocation', false)
        )
    },
    getBuyersHeatmapGeoJSON ({ commit }, address) {
      commit('loadingBuyerHeatmapGeoJSON', true)
      return api
        .getBuyersMarketDemandGeoJSON(address)
    },
    getBuyerSearchAreaGeoJSON ({commit}, buyerID) {
      commit('loadingBuyerSearchAreaGeoJSON', true)
      return api
        .getBuyerSearchAreaGeoJSON(buyerID)
        .finally(() =>
          commit('loadingBuyerSearchAreaGeoJSON', false)
        )
    },
    getBloopData ({ commit }, payload) {
      commit('loadingBloopObj', true)
      return api.
        getBloopData(payload)
        .then(function(data) {
          return data.items
        })
        .finally(() =>
          commit('loadingBloopObj', false)
        )
    },

  }
}
