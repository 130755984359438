import api from '@/api'
import {copyIntoRecursive,
        default_getter as get,
        default_setter as set} from '@/store'

const getInitialState = function () {
  return {
    marketTrends: [],
    demographics: [],
    schools: [],
    avms: [],
    supplySideTrends: [],
    relatedProperties: {},
    loadedReportId: null,

    // Page 1 Autocomplete:
    addresses: [],            // this stores just the address for autocomplete
    addressesFullObjects: [], // this stores the entire address object for autocomplete
    verifyAddressObject: {},  // used for verifying a user-supplied address (no autocomp match)

    activeListingRedirect: null,

    /* Loading Area */
    loadingSupplySideTrends: true,
    loadingMarketTrends: true,
    loadingAvms: false,
    loadingRelatedProperties: true,
    loadingDemographics: true
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    avms:                     get('avms'),
    marketTrends:             get('marketTrends'),
    demographics:             get('demographics'),
    schools:                  get('schools'),
    relatedProperties:        get('relatedProperties'),
    addresses:                get('addresses'),
    addressObjectByAddress: (state) => (lookupAddress) => {
      var address = state.addressesFullObjects.filter(address => address.Address.Address == lookupAddress)
      if(address.length) {
        return address[0]
      }
      return null
    },
    marketTrendsMonths (state) {
      const months = []
      state.marketTrends.forEach(function(dataPoint) {
        months.push(dataPoint.year * 100 + dataPoint.month)
      })
      return months
    },
    medianPriceValues (state) {
      const medianPrices = []
      state.marketTrends.forEach(function(dataPoint) {
        medianPrices.push(dataPoint.median_sale_price)
      })
      return medianPrices
    },
    housesSoldValues (state) {
      const housesSold = []
      state.marketTrends.forEach(function(dataPoint) {
        housesSold.push(dataPoint.homes_sold)
      })
      return housesSold
    },
    housesSoldQuarters: () => [],
    priceSqftValues (state) {
      const priceSqft = []
      state.marketTrends.forEach(function(dataPoint) {
        priceSqft.push(dataPoint.avg_price_per_sqft)
      })
      return priceSqft
    },

    /* Loading Getters */
    loadingMarketTrends:      get('loadingMarketTrends'),
    loadingDemographics:      get('loadingDemographics'),
    loadingAvms:              get('loadingAvms'),
    loadingRelatedProperties: get('loadingRelatedProperties'),
  },
  mutations: {
    _resetState(state) {
      copyIntoRecursive(state, getInitialState())
    },
    setMarketTrends:          set('marketTrends'),
    // setDemographics:          set('demographics'),
    setAvms:                  set('avms'),
    setSupplySideTrends:      set('supplySideTrends'),
    setLoadedReportId:        set('loadedReportId'),
    setVerifyAddressObject:   set('verifyAddressObject'),
    setActiveListingRedirect: set('activeListingRedirect'),

    setDemographics (state, payload) {
      state.demographics = payload.data.demographics
      state.schools = payload.data.schools
    },

    setRelatedProperties (state, payload) {
      if(!state.loadedReportId ||
          state.loadedReportId != payload.request_params.report_id)
      {
        //this is a new report load, so start from scratch
        state.relatedProperties = {
          related_properties: new Array(payload.response.total).fill(null),
          total: payload.response.total
        }
      }

      //now save a page of data
      state.relatedProperties.related_properties.splice(
        payload.request_params.offset,
        payload.response.related_properties.length,
        ...payload.response.related_properties
      )
    },
    setAddresses (state, value) {
      state.addressesFullObjects = value
      let addresses = []
      value.forEach(function(address) {
        let obj =
         {text:  address.Address.Address,
          value: address.Address.Address,
          hasSubs: false};

        if(address.Address.SubBuilding)
        {
          let subs = address.Address.SubBuilding.split(',').filter(y=>y);
          if(subs.length == 1) {
            //this is a special case where we will just flatten this one subunit
            // with its main address and pretend like it's a main address
            obj.text  = `${address.Address.Address1}, ${subs[0]}, ${address.Address.Address2}`
          }
          else if(subs.length > 1) {
            obj.subs = subs.map(
              subname => ({
                text: subname,
                value: subname,
                isSub: true
              })
            );
            obj.hasSubs = true;
          }
        }
        addresses.push(obj)
      })
      state.addresses = addresses
    },

    /* Loading Mutations */
    loadingSupplySideTrends:      set('loadingSupplySideTrends'),
    loadingMarketTrends:          set('loadingMarketTrends'),
    loadingDemographics:          set('loadingDemographics'),
    loadingAvms:                  set('loadingAvms'),
    loadingRelatedProperties:     set('loadingRelatedProperties')
  },
  actions: {
    getMarketTrends ({ commit }, params) {
      commit('loadingMarketTrends', true)
      return api
        .getMarketTrends(params)
        .then(function (marketTrends) {
          marketTrends.sort(function (a, b) {
            var aYear = a.year;
            var bYear = b.year;
            var aMonth = a.month;
            var bMonth = b.month;

            if(aYear == bYear)
            {
              return (aMonth < bMonth) ? -1 : (aMonth > bMonth) ? 1 : 0;
            }
            else
            {
              return (aYear < bYear) ? -1 : 1;
            }
          });
          return marketTrends
        })
    },
    getAvms ({commit}, obj) {
      commit('loadingAvms', true)
      return api
        .getAvms(obj)
        .finally(()=>{
          commit('loadingAvms', false)
        })
    },
    getSupplySideTrends ({ commit }, address) {
      commit('loadingSupplySideTrends', true)
      return api
        .getSupplySideTrends(address)
        .then(function (supplySideTrends) {
          commit('setSupplySideTrends', supplySideTrends)
        }).finally(()=>{
          commit('loadingSupplySideTrends', false)
        })
    },
    getRelatedProperties ({ commit, state }, obj) {
      commit('loadingRelatedProperties', true)
      let _obj = {
        limit: obj.limit != null ? obj.limit : 5,
        offset: obj.offset != null ? obj.offset : 0,
        report_id: obj.report_id
      };
      return api
        .getRelatedProperties(obj)
        .then( relatedProperties =>
          {
            commit('setRelatedProperties', {
              response: relatedProperties,
              request_params: _obj
            })
            if(state.loadedReportId != _obj.report_id) {
              commit('setLoadedReportId', _obj.report_id)
            }
          }
        ).finally(() => {
          commit('loadingRelatedProperties', false)
        })
    },
    getAutocomplete ({ commit, rootState }, val) {
      return api
        .getAutocomplete(val, rootState.config.config.smartystreets_states_filter)
        .then(function (addresses) {
          commit('setAddresses', addresses)
        })
    },
    getActiveListingRedirect (context, params) {
      //console.log(params)
      return api
        .getActiveListingRedirect(params)
    },
    verifyAddress (context, input) {
      let addressString = input;
      if (typeof input == "object"){
        addressString =
          input.address1 + " " +
          input.address2 + " " +
          input.city + " " +
          input.state + " " +
          input.zipcode;
      }

      return api.
        verifyAddress(addressString)
        .then(response => {
          return response
        })
    },
    getDemographics (context, params) {
      return api
        .getDemographics(params)
    }
  }
}
