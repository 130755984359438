////////////////////////////////////////
// THEME GENERATION UTILTIY FUNCTIONS //
////////////////////////////////////////

export type RGB = [number, number, number]
type LAB = RGB
type HSL = {h: number; s: number; l: number}

export interface ThemeStruct {
      typography: {
        primary_font:               string;
        primary_font_URL:           string;
        secondary_font:             string;
        secondary_font_URL:         string;
        button_font:                string;
        button_font_URL:            string;
        button_weight:              string;
        paragraph_font:             string;
        paragraph_font_URL:         string;
      };
      colors: {
        primary:                    string;
        primary_font_contrast:      string;
        secondary:                  string;
        secondary_font_contrast:    string;
        button:                     string;
        button_font_contrast:       string;
      };
      dimensions: {
        button_border_radius:       string;
      };
    }

// Function to calculate "perceived difference in color"
// https://stackoverflow.com/questions/13586999/color-difference-similarity-between-two-values-with-js
// https://zschuessler.github.io/DeltaE/learn/
export function deltaE(rgbA: RGB, rgbB: RGB): number {
    const labA = rgb2lab(rgbA);
    const labB = rgb2lab(rgbB);
    const deltaL = labA[0] - labB[0];
    const deltaA = labA[1] - labB[1];
    const deltaB = labA[2] - labB[2];
    const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    const deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    const sc = 1.0 + 0.045 * c1;
    const sh = 1.0 + 0.015 * c1;
    const deltaLKlsl = deltaL / (1.0);
    const deltaCkcsc = deltaC / (sc);
    const deltaHkhsh = deltaH / (sh);
    const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
}

// Convert RGB triplet to LAB color space
// https://stackoverflow.com/questions/13586999/color-difference-similarity-between-two-values-with-js
function rgb2lab(rgb: RGB): LAB {
    let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
    y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
    z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;
    return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
}

// Convert hexadecimal color string to LAB colorspace
export function hex2lab(hex: string): LAB | null {

    const rgb = hex2rgb(hex)

    if(!rgb) return null

    return rgb2lab(rgb)
}

// Convert hexadecimal color string to RGB colorspace
export function hex2rgb(hex: string): RGB | null {

    // To test for a valid hex value
    const HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

    const result =  HEX_REGEX.exec(hex)

    if(!result) return null

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return [r,g,b]
}

export const hexToHSL = function(hex: string, fallbackHex = ''): HSL
{
    // To test for a valid hex value
    const HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

    const result =  HEX_REGEX.exec(hex) ??
                    HEX_REGEX.exec(fallbackHex);

    if(!result) return {h:0, s:0, l:0}; // at least return something valid

    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = Math.round(100*s);
    l = Math.round(100*l);
    h = Math.round(360*h);

    //var colorInHSL = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
    return {h, s, l};   //h is degrees (0-360). s, l are percentage points (0-100).
}


/*
from
https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
and aligned with the way Bootstrap SCSS does this with their `color-yiq` function
(see _functions.scss in Bootstrap source)
*/
const determineContrastFontColor = function (bgColor: string, lightColor = '#FFFFFF', darkColor = '#212529') {
    const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB

    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    return (yiq >= 150) ? darkColor : lightColor;
}

/*
    This literally adds/subtracts from the lightness value of the current hsl color ([0,100]).
    If the addend is too large, it will result in full white or full black.
     i.e., fn({100, 50%, 10%}, -20) --> 10 + -20 --> 0 lightness == pure black.
*/
const addLightness = function ({h, s, l}: HSL, lightnessToAddInPercentPoints: number): HSL
{
    let newL = l + lightnessToAddInPercentPoints;
    if (newL < 0) newL = 0;
    if (newL > 100) newL = 100;
    return {h, s, l: newL};
}

/*
    Unlike `addLightness`, this computes a proportion between the current color and white or black.
    addLightness adds/subtracts from the lightness value; this fn mulitplies a percent against it.
    (Same as the SASS function mix())
        -1 < shadeTintFactor < 1
    shadeTintFactor 1       == pure white
    shadeTintFactor 0.5     == 50% white, 50% input color
    shadeTintFactor 0       == exact input color
    shadeTintFactor -0.5    == 50% black, 50% input color
    shadeTintFactor -1      == pure black
*/
export const adjustLightness = function ({h, s, l}: HSL, shadeTintFactor: number): HSL
{
    if(shadeTintFactor == 0) {
        return {h, s, l};
    }
    else if (shadeTintFactor > 0)
    {
        shadeTintFactor = Math.min(shadeTintFactor, 1);
        const delta = (100 - l);
        const newL = l + delta*shadeTintFactor;
        return {h, s, l: newL };
    }
    else //shadeTintFactor < 0
    {
        shadeTintFactor = Math.max(shadeTintFactor, -1);
        const delta = l;
        const newL = l + delta*shadeTintFactor;
        return {h, s, l: newL };
    }
}

// We need to use this to generate the partials that are necessary
//  for the SASS-generated CSS to do their own lightness/shade computations.
//  e.g. for the demand funnel tier shading, using our custom `_lightness()` SASS function
const extrapolateHSL = function (cssVariableName: string, hexColor: string, fallbackHexColor: string)
{
    const {h, s, l} = hexToHSL(hexColor, fallbackHexColor);
    return `
        ${cssVariableName}:       hsl(${h}, ${s}%, ${l}%);
        ${cssVariableName}-h:     ${h};
        ${cssVariableName}-s:     ${s}%;
        ${cssVariableName}-l:     ${l}%;
    `;
}

export const HSLAtext: (x: HSL & {a?: number}) => string =
    ({h, s, l, a=1}) => `hsla(${h}, ${s}%, ${l}%, ${a})`


const fontImport = function (URL_array: string[]) {
    if(!Array.isArray(URL_array) || !URL_array.length) return '';

    return [...new Set(URL_array)] //remove dupes
        .filter(x => x) //remove blanks
        .map(URL => `@import url("${URL}");`)
        .join('\n')
}

const fontString = function (newFonts = "", defaultFonts = "") {
    if(newFonts && defaultFonts)
        return newFonts + ", " + defaultFonts;
    else if (newFonts)
        return newFonts;
    else if (defaultFonts)
        return defaultFonts;
    else return "inherit";
}

//
// Main CSS generation script
//
const generateTheme = function (
    newThemeJSON: ThemeStruct,
    defaultThemeJSON: ThemeStruct,
    button_hex_from_config_struct: string
): string
{
    const   t = newThemeJSON, // == response from config API
            d = defaultThemeJSON;

    let button_color, button_font_color;
    // Regex test: is valid hex code?
    if (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(button_hex_from_config_struct))
    {
        button_color = button_hex_from_config_struct;
        button_font_color = determineContrastFontColor(button_hex_from_config_struct)
    }
    else
    {
        // The button color was not explicitly provided,
        // so we will fallback to theme colors as the button color.
        button_color = t.colors.button || t.colors.secondary || d.colors.secondary;

        button_font_color = t.colors.button_font_contrast ||
            t.colors.secondary_font_contrast ||
            determineContrastFontColor(button_color);
    }

    const   primaryHSL    = hexToHSL(t.colors.primary,   d.colors.primary),
            secondaryHSL  = hexToHSL(t.colors.secondary, d.colors.secondary),
            buttonHSL     = hexToHSL(button_color,       d.colors.button);

    const css = `
    ${fontImport([  t.typography.primary_font_URL,
                    t.typography.secondary_font_URL,
                    t.typography.button_font_URL,
                    t.typography.paragraph_font_URL ])}

    :root, :host {
        --tif-theme-primary-font:             ${fontString(t.typography.primary_font,   d.typography.primary_font)};
        --tif-theme-secondary-font:           ${fontString(t.typography.secondary_font, d.typography.secondary_font)};
        --tif-theme-button-font:              ${fontString(t.typography.button_font,    d.typography.button_font)};
        --tif-theme-button-weight:            ${t.typography.button_weight};
        --tif-theme-paragraph-font:           ${fontString(t.typography.paragraph_font, d.typography.paragraph_font)};

        --tif-theme-button-border-radius:     ${t.dimensions.button_border_radius};

        ${extrapolateHSL('--tif-theme-primary-color',         t.colors.primary,                 d.colors.primary ) }
        ${extrapolateHSL('--tif-theme-primary-font-color',    t.colors.primary_font_contrast,   d.colors.primary_font_contrast ) }
        ${extrapolateHSL('--tif-theme-secondary-color',       t.colors.secondary,               d.colors.secondary ) }
        ${extrapolateHSL('--tif-theme-secondary-font-color',  t.colors.secondary_font_contrast, d.colors.secondary_font_contrast ) }
        ${extrapolateHSL('--tif-theme-button-color',          button_color,                     d.colors.button ) }
        ${extrapolateHSL('--tif-theme-button-font-color',     button_font_color,                d.colors.button_font_contrast ) }

        --tif-primary:                              ${HSLAtext( primaryHSL )};
        --tif-primary--25alpha:                     ${HSLAtext( {...primaryHSL, a: .25} )};
        --tif-primary--50alpha:                     ${HSLAtext( {...primaryHSL, a: .5} )};
        --tif-primary--48white:                     ${HSLAtext( adjustLightness(primaryHSL, .48) )};
        --tif-primary--48black:                     ${HSLAtext( adjustLightness(primaryHSL, -.48) )};
        --tif-primary--48black-dark10:              ${HSLAtext( addLightness( adjustLightness(primaryHSL, -.48), -10) )};
        --tif-primary--72black:                     ${HSLAtext( adjustLightness(primaryHSL, -.72) )};
        --tif-primary--72black-dark5:               ${HSLAtext( addLightness( adjustLightness(primaryHSL, -.72), -5) )};
        --tif-primary--80black:                     ${HSLAtext( adjustLightness(primaryHSL, -.8) )};
        --tif-primary--dark7pt5:                    ${HSLAtext( addLightness(primaryHSL, -7.5) )};
        --tif-primary--dark7pt5-50alpha:            ${HSLAtext( {...addLightness(primaryHSL, -7.5), a: .5} )};
        --tif-primary--dark10:                      ${HSLAtext( addLightness(primaryHSL, -10) )};
        --tif-primary--dark12pt5:                   ${HSLAtext( addLightness(primaryHSL, -12.5) )};
        --tif-primary--dark15:                      ${HSLAtext( addLightness(primaryHSL, -15) )};
        --tif-primary--64white:                     ${HSLAtext( adjustLightness(primaryHSL, .64) )};
        --tif-primary--72white:                     ${HSLAtext( adjustLightness(primaryHSL, .72) )};
        --tif-primary--74white:                     ${HSLAtext( adjustLightness(primaryHSL, .74) )};
        --tif-primary--80white:                     ${HSLAtext( adjustLightness(primaryHSL, .80) )};
        --tif-primary--90white:                     ${HSLAtext( adjustLightness(primaryHSL, .90) )};
        --tif-primary--72white-85alpha:             ${HSLAtext( {...adjustLightness(primaryHSL, .72), a: .85} )};
        --tif-primary--80white-85alpha:             ${HSLAtext( {...adjustLightness(primaryHSL, .80), a: .85} )};
        --tif-primary--90white-85alpha:             ${HSLAtext( {...adjustLightness(primaryHSL, .90), a: .85} )};

        --tif-secondary:                            ${HSLAtext( secondaryHSL )};
        --tif-secondary--50alpha:                   ${HSLAtext( {...secondaryHSL, a: .5} )};
        --tif-secondary--48black:                   ${HSLAtext( adjustLightness(secondaryHSL, -.48) )};
        --tif-secondary--48black-dark10:            ${HSLAtext( addLightness( adjustLightness(secondaryHSL, -.48), -10) )};
        --tif-secondary--72black:                   ${HSLAtext( adjustLightness(secondaryHSL, -.72) )};
        --tif-secondary--72black-dark5:             ${HSLAtext( addLightness( adjustLightness(secondaryHSL, -.72), -5) )};
        --tif-secondary--80black:                   ${HSLAtext( adjustLightness(secondaryHSL, -.8) )};
        --tif-secondary--dark7pt5:                  ${HSLAtext( addLightness(secondaryHSL, -7.5) )};
        --tif-secondary--dark7pt5-50alpha:          ${HSLAtext( {...addLightness(secondaryHSL, -7.5), a: .5} )};
        --tif-secondary--dark10:                    ${HSLAtext( addLightness(secondaryHSL, -10) )};
        --tif-secondary--dark12pt5:                 ${HSLAtext( addLightness(secondaryHSL, -12.5) )};
        --tif-secondary--dark15:                    ${HSLAtext( addLightness(secondaryHSL, -15) )};
        --tif-secondary--64white:                   ${HSLAtext( adjustLightness(secondaryHSL, .64) )};
        --tif-secondary--72white:                   ${HSLAtext( adjustLightness(secondaryHSL, .72) )};
        --tif-secondary--74white:                   ${HSLAtext( adjustLightness(secondaryHSL, .74) )};
        --tif-secondary--80white:                   ${HSLAtext( adjustLightness(secondaryHSL, .80) )};
        --tif-secondary--90white:                   ${HSLAtext( adjustLightness(secondaryHSL, .90) )};
        --tif-secondary--72white-85alpha:           ${HSLAtext( {...adjustLightness(secondaryHSL, .72), a: .85} )};
        --tif-secondary--80white-85alpha:           ${HSLAtext( {...adjustLightness(secondaryHSL, .80), a: .85} )};
        --tif-secondary--90white-85alpha:           ${HSLAtext( {...adjustLightness(secondaryHSL, .90), a: .85} )};

        --tif-button:                               ${HSLAtext( buttonHSL )};
        --tif-button--25alpha:                      ${HSLAtext( {...buttonHSL, a: .25} )};
        --tif-button--50alpha:                      ${HSLAtext( {...buttonHSL, a: .5} )};
        --tif-button--48white:                      ${HSLAtext( adjustLightness(buttonHSL, .48) )};
        --tif-button--48black:                      ${HSLAtext( adjustLightness(buttonHSL, -.48) )};
        --tif-button--48black-dark10:               ${HSLAtext( addLightness( adjustLightness(buttonHSL, -.48), -10) )};
        --tif-button--72black:                      ${HSLAtext( adjustLightness(buttonHSL, -.72) )};
        --tif-button--72black-dark5:                ${HSLAtext( addLightness( adjustLightness(buttonHSL, -.72), -5) )};
        --tif-button--80black:                      ${HSLAtext( adjustLightness(buttonHSL, -.8) )};
        --tif-button--dark7pt5:                     ${HSLAtext( addLightness(buttonHSL, -7.5) )};
        --tif-button--dark7pt5-50alpha:             ${HSLAtext( {...addLightness(buttonHSL, -7.5), a: .5} )};
        --tif-button--dark10:                       ${HSLAtext( addLightness(buttonHSL, -10) )};
        --tif-button--dark12pt5:                    ${HSLAtext( addLightness(buttonHSL, -12.5) )};
        --tif-button--dark15:                       ${HSLAtext( addLightness(buttonHSL, -15) )};
    }`;
    return css;
}

export default generateTheme;
